import { Box } from "@mui/material";
import React from "react";
import Hero from "../../components/homeComponents/Hero";
import Carousel from "../../components/homeComponents/Carousel";
import AboutUsHome from "../../components/homeComponents/AboutUsHome";
import BeautySalonStats from "../../components/homeComponents/BeautySalonStats";
import ServicesHome from "../../components/homeComponents/ServicesHome";
import Price from "../../components/homeComponents/Price";
import Gallery2 from "../../components/homeComponents/Gallery2";
import ServiceHome2 from "../../components/homeComponents/ServiceHome2";
import BlogHome from "../../components/homeComponents/BlogHome";
import CustomSlider from "../../components/homeComponents/CustomSlider";
import VideoComponent from "../../components/homeComponents/VideoComponent";

function Home() {
  return (
    <Box>
      <Hero />
      <Carousel />
      <AboutUsHome />

      <Price />
      <CustomSlider />
      <BeautySalonStats />
      <ServicesHome />
      <ServiceHome2 />

      <Gallery2 />
      <VideoComponent />
      <BlogHome />
    </Box>
  );
}

export default Home;
