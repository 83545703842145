import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link as MuiLink,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import emailjs from "emailjs-com";
import heroImage from "../../assets/salon/salon.avif";

const SERVICE_ID = process.env.REACT_APP_SERVICEID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATEID;
const USER_ID = process.env.REACT_APP_USERID;

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {
      name: formValues.name.trim() === "",
      email: formValues.email.trim() === "" || !validateEmail(formValues.email),
      message: formValues.message.trim() === "",
    };
    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      setErrorMessage("Please fill in all required fields correctly.");
      setSuccessMessage("");
      return;
    }

    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: formValues.name,
          reply_to: formValues.email,
          phone: formValues.phone,
          message: formValues.message,
          to_email: "info@team-ett.se",
        },
        USER_ID
      )
      .then(() => {
        setSuccessMessage("Message successfully sent!");
        setErrorMessage("");
        setFormValues({ name: "", email: "", phone: "", message: "" });
      })
      .catch((error) => {
        console.error("EmailJS Error:", error);
        setErrorMessage("Failed to send the message. Please try again.");
        setSuccessMessage("");
      });
  };

  return (
    <Box sx={{ backgroundColor: "#fff", pb: 10 }}>
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          height: "50vh",
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "white",
          marginTop: "-110px",
        }}
      >
        {/* Overlay with no pointer events so the link below is clickable */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
            pointerEvents: "none",
          }}
        />
        {/* Text container with pointer events */}
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            pointerEvents: "auto",
            p: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "400",
              fontFamily: "'Prata', serif",
              fontSize: { xs: "40px", md: "54px" },
              marginBottom: "15px",
              lineHeight: "1.3",
            }}
          >
            Contact Us
          </Typography>
          <Box
            sx={{
              backgroundColor: "#c59d5f",
              display: "inline-block",
              padding: "8px 20px",
              borderRadius: "4px",
              marginTop: "15px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              <MuiLink
                component={RouterLink}
                to="/"
                underline="none"
                sx={{
                  color: "inherit",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                HOME
              </MuiLink>
              {" → CONTACT"}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: "1200px",
          mx: "auto",
          mt: 8,
          px: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
        }}
      >
        {/* LEFT COLUMN: replaced info from Footer */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mb: 2,
              fontSize: { xs: "24px", md: "28px" },
            }}
          >
            TeamEtt
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Playfair Display', serif",
              mb: 2,
              color: "#555",
              fontSize: "16px",
            }}
          >
            Premium beauty salon & salon services tailored for your perfect
            style.
          </Typography>

          {/* Address */}
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Address:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FaMapMarkerAlt style={{ marginRight: 5, color: "#c59d5f" }} />
            <Typography
              variant="body2"
              sx={{
                fontFamily: "'Playfair Display', serif",
                fontSize: "16px",
              }}
            >
              Magnus Ladulåsgatan 61, 118 27 Stockholm
            </Typography>
          </Box>

          {/* Email */}
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Email:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FaEnvelope style={{ marginRight: 5, color: "#c59d5f" }} />
            <MuiLink
              href="mailto:info@teamett.com"
              underline="none"
              sx={{
                color: "#555",
                fontFamily: "'Playfair Display', serif",
                fontSize: "16px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              info@teamett.com
            </MuiLink>
          </Box>

          {/* Phone */}
          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Phone:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FaPhoneAlt style={{ marginRight: 5, color: "#c59d5f" }} />
            <MuiLink
              href="tel:+46736530910"
              underline="none"
              sx={{
                color: "#555",
                fontFamily: "'Playfair Display', serif",
                fontSize: "16px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              073-653 09 10
            </MuiLink>
          </Box>
        </Box>

        {/* RIGHT COLUMN: Contact Form */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontFamily: "'Playfair Display', serif",
            }}
          >
            Send Us A Message
          </Typography>
          <Typography sx={{ color: "#555", mb: 3 }}>
            Fill out the form below, and we'll get back to you as soon as
            possible.
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              sx={{ mb: 2 }}
              value={formValues.name}
              onChange={handleChange}
              error={formErrors.name}
              helperText={formErrors.name ? "Name is required" : ""}
            />
            <TextField
              fullWidth
              label="Email address"
              name="email"
              variant="outlined"
              sx={{ mb: 2 }}
              value={formValues.email}
              onChange={handleChange}
              error={formErrors.email}
              helperText={formErrors.email ? "Valid email is required" : ""}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              variant="outlined"
              sx={{ mb: 2 }}
              value={formValues.phone}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Your Message"
              name="message"
              variant="outlined"
              multiline
              rows={4}
              sx={{ mb: 3 }}
              value={formValues.message}
              onChange={handleChange}
              error={formErrors.message}
              helperText={formErrors.message ? "Message is required" : ""}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#db9d47",
                color: "#fff",
                width: "100%",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "12px",
                "&:hover": { backgroundColor: "#b8863b" },
              }}
            >
              Get in touch
            </Button>
          </form>

          {successMessage && (
            <Typography sx={{ color: "green", mt: 2 }}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography sx={{ color: "red", mt: 2 }}>{errorMessage}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
