import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  FaCut,
  FaPalette,
  FaHandSparkles,
  FaMagic,
  FaWind,
  FaUserAlt,
} from "react-icons/fa";
import smainImage from "../../assets/smain1.png";
import s1 from "../../assets/hairImages/1.avif";
import s2 from "../../assets/hairImages/3.avif";
import s3 from "../../assets/nails/4.avif";
import s4 from "../../assets/hairImages/7.avif";
import s5 from "../../assets/hairImages/12.avif";
import s6 from "../../assets/men/3.avif";
import { useLanguage } from "../../constants/LanguageContext";
import { servicesContent } from "../../constants/textConstants";

// Map icons and images
const iconsMap = {
  FaCut: <FaCut size={36} />,
  FaPalette: <FaPalette size={36} />,
  FaHandSparkles: <FaHandSparkles size={36} />,
  FaMagic: <FaMagic size={36} />,
  FaWind: <FaWind size={36} />,
  FaUserAlt: <FaUserAlt size={36} />,
};

const imagesMap = { s1, s2, s3, s4, s5, s6 };

// Reusable styles
const serviceItemStyles = {
  position: "relative",
  padding: "20px",
  overflow: "hidden",
  height: "150px",
  display: "flex",
  alignItems: "center",
  gap: "15px",
  opacity: 0,
  transform: "translateY(20px)",
  transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: "100%",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 0,
    transition: "left 0.3s ease-in-out, opacity 0.5s ease-in-out",
  },
  "&:hover::before": {
    left: 0,
    opacity: 1,
  },
  "&:hover *": {
    color: "white !important",
  },
  "&:hover svg": {
    color: "black !important",
  },
};

const ServiceItem = React.memo(({ service, align, isVisible }) => {
  const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
    imagesMap[service.image]
  })`;

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        ...serviceItemStyles,
        justifyContent: align === "right" ? "flex-end" : "flex-start",
        paddingRight: align === "right" ? "20px" : 0,
        paddingLeft: align === "left" ? "20px" : 0,
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        "&::before": {
          ...serviceItemStyles["&::before"],
          backgroundImage,
        },
      }}
    >
      <Box sx={{ position: "relative", zIndex: 2, color: "#db9d47" }}>
        {iconsMap[service.icon]}
      </Box>
      <Box sx={{ position: "relative", zIndex: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "'Prata', serif",
          }}
        >
          {service.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#666",
            fontSize: "16px",
            fontFamily: "'Prata', serif",
          }}
        >
          {service.desc}
        </Typography>
      </Box>
    </Box>
  );
});

const ServicesHome = () => {
  const { language } = useLanguage();
  const langKey = language || "sv";
  const { sectionTitle, title, subtitle, services } =
    servicesContent[langKey] || servicesContent["sv"];

  const [isVisibleArray, setIsVisibleArray] = useState(
    new Array(services.length).fill(false)
  );
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          services.forEach((_, index) => {
            setTimeout(() => {
              setIsVisibleArray((prev) => {
                const newArray = [...prev];
                newArray[index] = true;
                return newArray;
              });
            }, index * 300);
          });
          observer.unobserve(entry.target);
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.3 }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, [services]);

  const [leftServices, rightServices] = useMemo(() => {
    return [services.slice(0, 3), services.slice(3)];
  }, [services]);

  return (
    <Box
      ref={sectionRef}
      id="services"
      sx={{
        textAlign: "center",
        padding: { xs: "40px 5%", md: "60px 10%", lg: "80px 15%" },
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
      >
        {sectionTitle}
      </Typography>

      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "30px", md: "54px" },
          fontFamily: "'Prata', serif",
          color: "#1C1C2E",
          marginBottom: "10px",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "24px",
          fontFamily: "'Prata', serif",
          marginBottom: "50px",
          color: "#1C1C2E",
        }}
      >
        {subtitle}
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" },
          gap: { xs: "30px", md: "40px", lg: "60px" },
        }}
      >
        <Box sx={{ textAlign: { xs: "center", md: "right" } }}>
          {leftServices.map((service, index) => (
            <ServiceItem
              key={index}
              service={service}
              align="right"
              isVisible={isVisibleArray[index]}
            />
          ))}
        </Box>
        <Box
          component="img"
          src={smainImage}
          alt="Main Service"
          sx={{
            width: "100%",
            paddingTop: "10%",
            maxWidth: "400px",
            display: { xs: "none", lg: "block" },
            margin: "0 auto",
            maskImage:
              "linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)",
            WebkitMaskImage:
              "linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%)",
          }}
        />

        <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
          {rightServices.map((service, index) => (
            <ServiceItem
              key={index + 3}
              service={service}
              align="left"
              isVisible={isVisibleArray[index + 3]}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ServicesHome);
