import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Container,
} from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import background from "../../assets/salon.jpg";
import { blogPostsContent } from "../../constants/textConstants";
import { useLanguage } from "../../constants/LanguageContext";
import { Link as MuiLink } from "@mui/material"; // Blog Post Images
import s1 from "../../assets/men/4.avif";
import s2 from "../../assets/s1.jpg";
import s3 from "../../assets/hairImages/11.avif";
import s4 from "../../assets/nails/5.avif";
import s5 from "../../assets/s5.jpg";
import s6 from "../../assets/s6.jpg";

const imageMap = {
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
};

const Blog = () => {
  const [expanded, setExpanded] = useState(null);
  const [searchParams] = useSearchParams();
  const { language } = useLanguage();
  const langKey = language || "sv";
  const blogPosts = Array.isArray(blogPostsContent?.[langKey]?.posts)
    ? blogPostsContent[langKey].posts
    : [];

  const navigate = useNavigate();
  const postIndex = searchParams.get("postIndex");
  const postRefs = useRef(blogPosts.map(() => React.createRef()));

  useEffect(() => {
    if (postIndex !== null) {
      const index = parseInt(postIndex, 10);
      if (index >= 0 && index < blogPosts.length) {
        setExpanded(index);
        postRefs.current[index]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [postIndex, blogPosts.length]);

  const handleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleSidebarClick = (index) => {
    navigate(`/blog?postIndex=${index}`);
  };

  return (
    <Box id="blog-page">
      <Box
        sx={{
          position: "relative",
          height: "50vh",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "white",
          marginTop: "-110px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          }}
        />
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontFamily: '"Playfair Display", serif',
              fontSize: { xs: "36px", md: "48px" },
            }}
          >
            Blogs
          </Typography>
          <Box
            sx={{
              backgroundColor: "#c59d5f",
              display: "inline-block",
              padding: "8px 20px",
              borderRadius: "4px",
              marginTop: "15px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              <MuiLink
                component="a"
                href="/"
                underline="none"
                sx={{
                  color: "inherit",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                {" → BLOGS"}
              </MuiLink>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          maxWidth: "1400px",
          margin: "auto",
          padding: "60px 20px",
          display: "flex",
          gap: "40px",
        }}
      >
        <Box sx={{ flex: 2 }}>
          {blogPosts.map((post, index) => (
            <Card
              key={index}
              ref={postRefs.current[index]}
              sx={{ marginBottom: "40px" }}
            >
              <Box sx={{ overflow: "hidden", height: "400px" }}>
                <CardMedia
                  component="img"
                  height="400"
                  image={imageMap[post.image] || post.image}
                  alt={post.title}
                  sx={{
                    transition: "transform 0.3s ease-in-out",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    "&:hover": { transform: "scale(1.2)" },
                  }}
                />
              </Box>
              <CardContent sx={{ textAlign: "left", padding: "30px" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "#C19A6B", fontWeight: "bold" }}
                >
                  {post.category} / By {post.author}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    margin: "15px 0",
                  }}
                >
                  {post.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#666" }}>
                  {post.description}
                </Typography>
                {expanded === index && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#666",
                      marginTop: "15px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {post.fullText}
                  </Typography>
                )}
                <Button
                  sx={{ marginTop: "20px", textDecoration: "underline" }}
                  onClick={() => handleExpand(index)}
                >
                  {expanded === index ? "Read Less" : "Read More"}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
        <Box
          sx={{ flex: 1, display: { xs: "none", md: "block", lg: "block" } }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontFamily: "'Playfair Display', serif",
              marginBottom: "20px",
            }}
          >
            Popular Posts
          </Typography>
          {blogPosts.map((post, index) => (
            <Box
              key={index}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                "&:hover": { opacity: 0.8 },
              }}
              onClick={() => handleSidebarClick(index)}
            >
              <CardMedia
                component="img"
                image={imageMap[post.image] || post.image}
                alt={post.title}
                sx={{
                  width: "100px",
                  height: "100px",
                  marginRight: "15px",
                  borderRadius: "5px",
                }}
              />
              <CardContent sx={{ padding: "0" }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  {post.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#777", fontSize: "12px" }}
                >
                  {post.category}
                </Typography>
              </CardContent>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Blog;
