export const hero = {
  sv: {
    heroTitle: "Upplev konsten av\nFelfri stil",
    heroDescription:
      "Kliva in i elegansen hos Team Ett. Från felfria frisyrer till fantastiska naglar och fängslande ögon, våra experter skapar skönhet skräddarsydd just för dig.",
    featureItems: "Din stil, vår expertis",
    contactUsButtonText: "Boka Tid",
  },
  en: {
    heroTitle: "Experience the Art of\nFlawless Style",
    heroDescription:
      "Step into elegance at Team Ett. From flawless haircuts to stunning nails and captivating eyes, our experts craft beauty tailored just for you.",
    featureItems: "Your Style, Our Expertise",
    contactUsButtonText: "Book Appointment",
  },
};

export const aboutUsContent = {
  sv: {
    sectionTitle: "— Om Oss",
    title: "Mästare i konsten av hårstil",
    text: `Team Ett Södermalm är en väletablerad skönhetssalong i hjärtat av Stockholm. I flera år har vi varit dedikerade till att erbjuda exceptionella hår- och skönhetstjänster, vilket säkerställer att varje kund lämnar vår salong med en förnyad känsla av självförtroende. Med en nyligen genomförd renovering erbjuder vår salong nu en modern, mysig och inbjudande atmosfär som förbättrar kundupplevelsen.
  
  Vårt team består av mycket skickliga yrkespersoner som brinner för frisör- och skönhetsvård. Oavsett om du letar efter en stilren klippning, en perfekt färgbehandling eller expertråd om hårvård, är våra specialister här för att möta dina behov. På Team Ett Södermalm kombinerar vi kreativitet, erfarenhet och uppmärksamhet på detaljer för att leverera enastående resultat, vilket gör oss till en av de mest pålitliga salongerna i Stockholm. Besök oss på Magnus Ladulåsgatan 61 och låt oss framhäva det bästa i ditt hår och din stil.`,
    buttonText: "Kontakta oss",
  },
  en: {
    sectionTitle: "— About Us",
    title: "Mastering the Art of Hair Style",
    text: `Team Ett Södermalm is a well-established beauty salon located in the heart of Stockholm. For years, we have been dedicated to providing exceptional hair and beauty services, ensuring that every client leaves feeling refreshed and confident. With a recent renovation, our salon now offers a modern, cozy, and inviting atmosphere that enhances the customer experience.
  
  Our team consists of highly skilled professionals who are passionate about hairdressing and beauty. Whether you're looking for a stylish haircut, a flawless color treatment, or expert advice on hair care, our specialists are here to meet your needs. At Team Ett Södermalm, we combine creativity, experience, and attention to detail to deliver outstanding results, making us one of the most trusted salons in Stockholm. Visit us at Magnus Ladulåsgatan 61 and let us bring out the best in your hair and style.`,
    buttonText: "Contact Us",
  },
};

export const pricingContent = {
  sv: {
    sectionTitle: "— Våra Priser",
    title: "Premiumtjänster till konkurrenskraftiga priser",
    buttonText: "BOKA TID",
    pricingData: [
      {
        category: "Hårklippning",
        services: [
          { name: "Damklippning (Kort Hår)", price: "499 SEK" },
          { name: "Damklippning (Långt Hår)", price: "649 SEK" },
          { name: "Herrklippning (Kort Hår)", price: "350 SEK" },
          { name: "Herrklippning (Långt Hår)", price: "450 SEK" },
          { name: "Pensionär (Man)", price: "290 SEK" },
          { name: "Pensionär (Kvinna)", price: "330 SEK" },
          { name: "Enkel Klippning", price: "150 SEK" },
        ],
      },
      {
        category: "Färgning & Slingor",
        services: [
          { name: "Hårfärgning", price: "Från 999 SEK" },
          { name: "Balayage", price: "Från 2,250 SEK" },
          { name: "Baby Lights", price: "Från 2,499 SEK" },
          { name: "Hårlockning", price: "Från 1,699 SEK" },
        ],
      },
      {
        category: "Naglar & Permanent",
        services: [
          { name: "Manikyr med Gel-lack", price: "550 SEK" },
          { name: "Pedikyr", price: "600 SEK" },
          { name: "Pedikyr med Nagellack", price: "700 SEK" },
          { name: "Nagelförlängning", price: "800 SEK" },
          { name: "Keratinbehandling", price: "Från 1,899 SEK" },
          { name: "Tvätt & Fön", price: "299 SEK" },
        ],
      },
    ],
  },
  en: {
    sectionTitle: "— Our Pricing",
    title: "Premium Services at Competitive Prices",
    buttonText: "BOOK APPOINTMENT",
    pricingData: [
      {
        category: "Haircuts",
        services: [
          { name: "Ladies Haircut (Short Hair)", price: "499 SEK" },
          { name: "Ladies Haircut (Long Hair)", price: "649 SEK" },
          { name: "Men's Haircut (Short Hair)", price: "350 SEK" },
          { name: "Men's Haircut (Long Hair)", price: "450 SEK" },
          { name: "Pensioner (Male)", price: "290 SEK" },
          { name: "Pensioner (Female)", price: "330 SEK" },
          { name: "Basic Haircut", price: "150 SEK" },
        ],
      },
      {
        category: "Dyeing & Loops",
        services: [
          { name: "Hair Coloring", price: "From 999 SEK" },
          { name: "Balayage", price: "From 2,250 SEK" },
          { name: "Baby Lights", price: "From 2,499 SEK" },
          { name: "Hair Curling", price: "From 1,699 SEK" },
        ],
      },
      {
        category: "Nails & Permanent",
        services: [
          { name: "Manicure with Gel Polish", price: "550 SEK" },
          { name: "Pedicure", price: "600 SEK" },
          { name: "Pedicure with Nail Polish", price: "700 SEK" },
          { name: "Nail Extension", price: "800 SEK" },
          { name: "Keratin Treatment", price: "From 1,899 SEK" },
          { name: "Washing & Drying", price: "299 SEK" },
        ],
      },
    ],
  },
};

export const kidsCornerContent = {
  sv: {
    sectionTitle: "— En Speciell Plats för Barn",
    title: "Barnhörnan i Vår Salong",
    subtitle: "Roligt, Kreativitet & Stiliga Klippningar",
    description: `I vår salong strävar vi efter att skapa en trivsam och familjevänlig miljö där barn kan känna sig trygga och underhållna. 
      Barnhörnan är en speciellt designad plats där barn kan leka, rita och umgås medan de väntar på sina föräldrar eller sin tur för en klippning.
      Vi erbjuder även professionella barnklippningar som inte bara ger en stilren look, utan också gör frisörbesöket till en positiv och rolig upplevelse. 

      Föräldrar kan slappna av och njuta av en lugn stund medan våra skickliga frisörer ser till att varje barn får en klippning anpassad efter deras personliga stil och behov.
      Vårt team har erfarenhet av att arbeta med barn och skapar en trygg och avslappnad atmosfär, oavsett om det är barnets första klippning eller en rutinmässig uppfräschning.

      Hos Team Ett Södermalm tror vi på att göra varje besök unikt och minnesvärt – för både vuxna och barn. Kom och upplev vår barnvänliga salong där skönhet och komfort möts!`,
  },
  en: {
    sectionTitle: "— A Special Place for Kids",
    title: "Kids' Corner in Our Salon",
    subtitle: "Fun, Creativity & Stylish Haircuts",
    description: `At our salon, we strive to create a welcoming and family-friendly atmosphere where children feel comfortable and entertained. 
      The Kids’ Corner is a specially designed area where kids can play, draw, and socialize while waiting for their parents or their turn for a haircut.
      We also offer professional children's haircuts that not only ensure a stylish look but also make the visit an enjoyable and fun experience.

      Parents can relax and enjoy a moment of peace while our skilled hairstylists provide tailored haircuts suited to each child’s individual style and needs.
      Our team is experienced in working with kids, creating a safe and comfortable space whether it's a first-time haircut or a routine trim.

      At Team Ett Södermalm, we believe in making every visit special and memorable – for both adults and children. Come and experience our kid-friendly salon where beauty and comfort come together!`,
  },
};

export const galleryContent = {
  sv: {
    sectionTitle: "— Upptäck Vårt Arbete",
    title: "Vår Galleri",
    subtitle: "En Visning av Elegans & Precision",
    description: `På Team Ett Södermalm erbjuder vi enastående skönhetsbehandlingar för hår, naglar och styling. 
      Vår salong är en fristad där professionalism möter kreativitet, och vi strävar efter att överträffa våra kunders förväntningar.
      Från eleganta frisyrer till perfekt formade naglar – vår passion är att framhäva din naturliga skönhet. 
      Låt vårt erfarna team skapa din drömstil i en avslappnad och exklusiv miljö.`,
  },
  en: {
    sectionTitle: "— Discover Our Work",
    title: "Our Gallery",
    subtitle: "A Showcase of Elegance & Precision",
    description: `At Team Ett Södermalm, we provide outstanding beauty treatments for hair, nails, and styling. 
      Our salon is a haven where professionalism meets creativity, and we strive to exceed our customers’ expectations. 
      From elegant hairstyles to perfectly shaped nails – our passion is to enhance your natural beauty. 
      Let our experienced team craft your dream look in a relaxed and exclusive environment.`,
  },
};

export const beautySalonStatsContent = {
  sv: {
    happyClients: "Nöjda Kunder & Växer",
    skilledSpecialists: "Skickliga Skönhetsspecialister",
    monthlyTreatments: "Skönhets- & Hårbehandlingar Månadsvis",
  },
  en: {
    happyClients: "Happy Clients & Counting",
    skilledSpecialists: "Skilled Beauty Specialists",
    monthlyTreatments: "Beauty & Hair Treatments Monthly",
  },
};

export const servicesContent = {
  sv: {
    sectionTitle: "— Höj Din Stil",
    title: "Våra Skönhets- & Frisörtjänster",
    subtitle: "Skräddarsytt för Dig",
    services: [
      {
        title: "KLIPPNINGAR",
        desc: "Kort, långt och pensionärsklippningar för män & kvinnor.",
        image: "s1",
        icon: "FaCut",
      },
      {
        title: "FÄRG & SLINGOR",
        desc: "Hårfärgning, balayage, babylights och hårlockning.",
        image: "s2",
        icon: "FaPalette",
      },
      {
        title: "NAGLAR",
        desc: "Manikyr, pedikyr och nagelförlängning för en perfekt look.",
        image: "s3",
        icon: "FaHandSparkles",
      },
      {
        title: "PERMANENT",
        desc: "Långvarig keratinbehandling för mjukt & starkt hår.",
        image: "s4",
        icon: "FaMagic",
      },
      {
        title: "STYLING",
        desc: "Professionell tvätt & föning för en perfekt finish.",
        image: "s5",
        icon: "FaWind",
      },
      {
        title: "HERRKLIPPNING",
        desc: "Kort & långt herrklipp för en stilren look.",
        image: "s6",
        icon: "FaUserAlt",
      },
    ],
  },
  en: {
    sectionTitle: "— Elevate Your Style",
    title: "Our Beauty & Hair Services",
    subtitle: "Tailored for You",
    services: [
      {
        title: "HAIRCUTS",
        desc: "Short, long, and pensioner haircuts for men & women.",
        image: "s1",
        icon: "FaCut",
      },
      {
        title: "DYEING & LOOPS",
        desc: "Hair coloring, balayage, baby lights, and hair curling.",
        image: "s2",
        icon: "FaPalette",
      },
      {
        title: "NAILS",
        desc: "Manicure, pedicure, and nail extensions for a polished look.",
        image: "s3",
        icon: "FaHandSparkles",
      },
      {
        title: "PERMANENT",
        desc: "Long-lasting keratin treatment for smooth & strong hair.",
        image: "s4",
        icon: "FaMagic",
      },
      {
        title: "STYLING",
        desc: "Professional washing & drying for the perfect finish.",
        image: "s5",
        icon: "FaWind",
      },
      {
        title: "MEN'S HAIRCUT",
        desc: "Short & long men's haircuts for a stylish appearance.",
        image: "s6",
        icon: "FaUserAlt",
      },
    ],
  },
};

export const blogPostsContent = {
  sv: {
    sectionTitle: "— Håll Dig Uppdaterad",
    title: "Utforska De Senaste Trenderna",
    subtitle: "Hårklippningar, Nagelvård och Mer",
    readMore: "Läs mer",
    posts: [
      {
        image: "s1",
        category: "Herrklippningar",
        author: "TeamEtt",
        title: "Topplista på Herrklippningar: Stilar Alla Bör Testa",
        description: "Upptäck de mest stiliga och moderna frisyrerna för män.",
        // Full text pulled from blogPostPage.sv[0]
        fullText: `Att hitta den perfekta herrfrisyren är avgörande för en snygg och självsäker look. Oavsett om du föredrar en lättskött stil eller en djärv, trendig frisyr kan rätt frisyr framhäva dina drag och matcha din personlighet. På skönhetssalongen TeamEtt i Södermalm, Stockholm, är våra expertbarberare specialiserade på både klassiska och moderna herrfrisyrer, inklusive fade-frisyrer, texturerade crops och stilrena pompadours.

Fade-frisyren är fortfarande ett toppval och ger en ren och prydlig look som fungerar bra med olika hårtyper. För den som vill ha mer textur ger en messy crop eller en lagerklippning en avslappnad men ändå snygg stil. Om du vill ha en tidlös, sofistikerad look kan du satsa på en bakåtkammad frisyr eller en sidbena för en elegant känsla.

Att välja en frisyr handlar inte bara om stil—det handlar också om att bevara hårets hälsa. Våra proffs på TeamEtt använder premiumprodukter för hårvård för att hålla ditt hår starkt, återfuktat och lätt att styla. Boka din tid redan idag på TeamEtt i Södermalm och låt oss hjälpa dig att hitta den perfekta herrfrisyren som passar din livsstil!`,
      },
      {
        image: "s2",
        category: "Damklippningar",
        author: "TeamEtt",
        title: "De Bästa Damfrisyrerna för Varje Ansiktsform",
        description: "Hitta den mest smickrande frisyren för din ansiktsform.",
        // Full text pulled from blogPostPage.sv[1]
        fullText: `Din frisyr spelar en avgörande roll för hela ditt utseende. Rätt frisyr framhäver inte bara din naturliga skönhet utan ökar också självförtroendet. På skönhetssalongen TeamEtt i Södermalm, Stockholm, är vi specialiserade på skräddarsydda damfrisyrer som kompletterar olika ansiktsformer och hårtyper.

Om du har ett runt ansikte kan långa lager eller vinklade bobs skapa en förlängande effekt. De med fyrkantig ansiktsform klär ofta i mjukare, vågiga frisyrer som ramar in ansiktet vackert. Ovala ansikten är de mest mångsidiga och passar både korta pixiefrisyrer och långa, flödande lager. Våra frisörer på TeamEtt analyserar din ansiktsstruktur och hårtyp för att rekommendera de bästa stilarna som framhäver dina bästa drag.

Regelbundna klippningar och rätt hårvård är avgörande för att hålla frisyren fräsch och hälsosam. Vi erbjuder personliga hårvårdsbehandlingar för att ge näring åt ditt hår och behålla dess glans och styrka. Besök TeamEtt i Södermalm, Stockholm, för en professionell konsultation och låt oss skapa en frisyr som passar din personlighet och livsstil!`,
      },
      {
        image: "s3",
        category: "Hårfärgning",
        author: "TeamEtt",
        title: "Hårfärgstrender: Från Balayage till Djärva Färger",
        description: "Utforska de senaste hårfärgstrenderna och teknikerna.",
        // Full text pulled from blogPostPage.sv[2]
        fullText: `Hårfärg handlar om mer än att bara byta nyans—det är ett sätt att uttrycka sin personlighet och förnya sin look. Oavsett om du vill ha en subtil, solkysst balayage eller en djärv färg som sticker ut, erbjuder TeamEtt skönhetssalong i Södermalm, Stockholm, professionell hårfärgning anpassad efter din stil.

Balayage är fortfarande en av de mest efterfrågade teknikerna tack vare dess naturliga, mjuka resultat som kräver minimalt underhåll. För dig som vill ha en mer fashionabel förändring är platinablont, intensiva röda nyanser och pastellfärger utmärkta val. Om du föredrar något riktigt iögonfallande blir starka färger som elektriskt blått, neon-grönt och ljuslila allt mer populära.

För att bibehålla hårfärgens intensitet krävs rätt eftervård. Använd sulfatfria schampon, djupverkande inpackningar och se till att göra regelbundna uppfräschningar för att hålla färgen levande. På TeamEtt använder vi högkvalitativa, ammoniakfria hårfärger som skyddar ditt hår samtidigt som de ger fantastiska resultat. Boka en tid för hårfärgning hos oss i Södermalm, Stockholm, och förvandla din look med en ny, livfull nyans!`,
      },
      {
        image: "s4",
        category: "Nagelskönhet",
        author: "TeamEtt",
        title: "Nageltrender: Färger och Designer Du Måste Prova",
        description: "Få inspiration från årets hetaste nageldesigner.",
        // Full text pulled from blogPostPage.sv[3]
        fullText: `Dina naglar speglar din personliga stil och förtjänar samma omsorg som håret. På skönhetssalongen TeamEtt i Södermalm, Stockholm, erbjuder vi professionella nagelbehandlingar, allt från trendiga designer och hållbar gelpolish till stärkande behandlingar.

Bland de största nageltrenderna just nu finns minimalistiska nude-nyanser, starka neonfärger och detaljerade handmålade motiv. Franska manikyrer har också gjort comeback, men i nya, moderna tappningar som färgglada tippar och ombréeffekter. För dig som gillar att sticka ut passar krom- och holografiska ytor som ger en futuristisk känsla.

Friska naglar kräver rätt omsorg, därför rekommenderar vi regelbundna manikyrer och näringsrika nagelbandsbehandlingar. Oavsett om du föredrar klassisk elegans eller djärvare designer hjälper våra nagelstylister på TeamEtt dig att uppnå en perfekt manikyr. Besök oss i Södermalm för en avkopplande nagelbehandling och låt dina naglar glänsa!`,
      },
      {
        image: "s5",
        category: "Hårvård & Styling",
        author: "TeamEtt",
        title: "Så Håller Du Ditt Hår Friskt: Tips Från Våra Experter",
        description:
          "Lär dig viktiga hårvårdstips för starkt och glänsande hår.",
        // Full text pulled from blogPostPage.sv[4]
        fullText: `Friskt hår handlar inte bara om styling—det kräver regelbunden vård och rätt produkter. På skönhetssalongen TeamEtt i Södermalm, Stockholm, tror vi på en helhetssyn på hårhälsa och erbjuder både expertis och salongsbehandlingar för att ditt hår ska må så bra som möjligt.

Ett av de vanligaste misstagen är att använda fel schampo och balsam för sin hårtyp. Tunt hår behöver lättare produkter som tillför volym, medan lockigt och torrt hår behöver mer fukt. Värmeskydd är också viktigt; använd alltid en värmeskyddande spray innan du fönar eller plattar för att undvika skador.

Djupverkande inpackningar, regelbundna toppklippningar och hårbottenmassage hjälper till att stimulera hårväxten och förbättra hårets allmänna hälsa. På TeamEtt erbjuder vi skräddarsydda hårbehandlingar som reparerar och vårdar skadat hår. Oavsett om du behöver återfuktning, stärkning eller en renande hårbottenbehandling kan du boka en tid på vår salong i Södermalm och ge ditt hår den omsorg det förtjänar!`,
      },
      {
        image: "s6",
        category: "Skönhet & Grooming",
        author: "TeamEtt",
        title: "Salong vs. DIY: När Ska Du Gå Till Frisören?",
        description:
          "Lär dig när det är bättre att låta en professionell ta hand om din skönhet.",
        // Full text pulled from blogPostPage.sv[5]
        fullText: `Att göra skönhetsbehandlingar hemma kan vara roligt och kostnadseffektivt, men vissa saker bör helst överlåtas till proffsen. På skönhetssalongen TeamEtt i Södermalm, Stockholm, har vi sett många misslyckade DIY-försök, från ojämna klippningar till skadade naglar, som kräver professionell hjälp i efterhand.

Hemmabobbar kan ofta ge ojämna längder, och hårfärg från vanliga förpackningar kan ge oväntade resultat som är svåra att korrigera. Samma sak gäller manikyrer; en gör-det-själv-manikyr håller inte lika länge som en professionell gel- eller akrylbehandling. Även om hemmabehandlingar kan fungera för enklare underhåll, är större förändringar som hårfärgning, klippningar och avancerad nageldesign bäst att överlåta till ett proffs.

Genom att besöka en salong får du tillgång till högkvalitativa produkter, yrkesskicklighet och resultat som håller längre. Våra stylister på TeamEtt använder avancerade verktyg och tekniker för att ge dig perfekt hår och naglar. Boka en tid hos oss i Södermalm, Stockholm, och upplev lyxen av professionell skönhetsvård!`,
      },
    ],
  },
  en: {
    sectionTitle: "— Stay Updated",
    title: "Explore the Latest Trends",
    subtitle: "Haircuts, Nail Care, and More",
    readMore: "Read More",
    posts: [
      {
        image: "s1",
        category: "Men's Haircuts",
        author: "TeamEtt",
        title: "Top Men's Haircuts: Styles Every Man Should Try",
        description:
          "Discover the most stylish and modern men's haircut trends.",
        // Full text pulled from blogPostPage.en[0]
        fullText: `Finding the perfect men's haircut is essential for a sharp and confident look. Whether you prefer a low-maintenance style or a bold, trendy cut, the right haircut can enhance your features and match your personality. At TeamEtt beauty salon in Södermalm, Stockholm, our expert barbers specialize in classic and modern men's hairstyles, including fade haircuts, textured crops, and stylish pompadours.

The fade haircut remains a top choice, offering a clean and polished look that works well with different hair types. For those who prefer more texture, the messy crop or layered cuts provide a relaxed yet stylish finish. If you want a timeless, sophisticated look, consider a slick-back or side-parted hairstyle that exudes elegance.

Choosing a haircut isn’t just about style—it’s about maintaining healthy hair as well. Our professionals at TeamEtt use premium hair care products to keep your hair strong, hydrated, and easy to style. Book your appointment today at TeamEtt Södermalm and let us help you achieve the perfect men's haircut that suits your lifestyle!`,
      },
      {
        image: "s2",
        category: "Women's Haircuts",
        author: "TeamEtt",
        title: "The Best Women's Haircuts for Every Face Shape",
        description:
          "Find the most flattering haircut for your face shape and hair type.",
        // Full text pulled from blogPostPage.en[1]
        fullText: `Your hairstyle plays a crucial role in defining your overall appearance. The right haircut not only enhances your natural beauty but also boosts confidence. At TeamEtt beauty salon in Södermalm, Stockholm, we specialize in custom women's haircuts that complement different face shapes and hair textures.

If you have a round face, long layers or angled bobs can help create a slimming effect. Those with square faces often suit softer, wavy styles that frame the face beautifully. Oval face shapes are the most versatile, allowing for both short pixie cuts and long flowing layers. Our hairstylists at TeamEtt analyze your face structure and hair type to recommend the best styles that highlight your best features.

Regular trims and proper hair maintenance are essential for keeping your haircut fresh and healthy. We offer personalized hair care treatments to nourish your hair and maintain its shine and strength. Visit TeamEtt in Södermalm, Stockholm, for a professional consultation and let us create a haircut that suits your personality and lifestyle!`,
      },
      {
        image: "s3",
        category: "Hair Coloring",
        author: "TeamEtt",
        title: "Hair Dye Trends: From Balayage to Bold Colors",
        description: "Explore the latest hair dye trends and techniques.",
        // Full text pulled from blogPostPage.en[2]
        fullText: `Hair coloring is more than just changing your shade—it’s a way to express your personality and enhance your look. Whether you want a subtle sun-kissed balayage or a bold, eye-catching color, TeamEtt beauty salon in Södermalm, Stockholm, offers expert hair coloring services tailored to your style.

Balayage remains one of the most requested hair coloring techniques because of its natural, blended effect that requires minimal maintenance. For those looking for a fashion-forward change, platinum blonde, fiery reds, and pastel hues are excellent choices. If you prefer something bold, vivid colors like electric blue, neon green, and bright purple are becoming increasingly popular.

Maintaining hair color requires proper aftercare. Using sulfate-free shampoos, deep conditioning treatments, and regular touch-ups help preserve vibrancy. At TeamEtt, we use high-quality, ammonia-free hair dyes that protect your hair while delivering stunning results. Book a hair coloring session with us in Södermalm, Stockholm, and transform your look with a vibrant new shade!`,
      },
      {
        image: "s4",
        category: "Nail Beauty",
        author: "TeamEtt",
        title: "Nail Trends: Must-Try Colors and Designs",
        description: "Get inspired by the hottest nail colors and art trends.",
        // Full text pulled from blogPostPage.en[3]
        fullText: `Your nails are a reflection of your personal style, and keeping them looking their best is just as important as haircare. At TeamEtt beauty salon in Södermalm, Stockholm, we offer professional nail services that include trendy designs, long-lasting gel polish, and strengthening treatments.

This year’s biggest nail trends include minimalist nude nails, vibrant neon colors, and intricate hand-painted art. French manicures have also made a comeback, with modern twists like colorful tips and ombré effects. For those who love statement nails, chrome and holographic finishes add a futuristic touch.

Healthy nails require proper care, which is why we recommend regular manicures and nourishing cuticle treatments. Whether you prefer classic elegance or bold designs, our nail experts at TeamEtt will help you achieve a flawless manicure. Visit us in Södermalm for a relaxing nail beauty session and let your nails shine!`,
      },
      {
        image: "s5",
        category: "Haircare & Styling",
        author: "TeamEtt",
        title: "How to Keep Your Hair Healthy: Tips from Salon Experts",
        description:
          "Learn essential haircare tips to maintain strong, shiny hair.",
        // Full text pulled from blogPostPage.en[4]
        fullText: `Healthy hair isn’t just about styling—it requires consistent care and the right products. At TeamEtt beauty salon in Södermalm, Stockholm, we believe in a holistic approach to hair health, offering expert advice and salon treatments to keep your hair looking its best.

One of the biggest hair mistakes people make is using the wrong shampoo and conditioner for their hair type. Fine hair needs lightweight formulas that add volume, while curly and dry hair benefits from moisture-rich products. Heat protection is another essential step; always use a heat protectant spray before blow-drying or straightening to prevent damage.

Deep conditioning treatments, regular trims, and scalp massages help stimulate hair growth and improve overall hair health. At TeamEtt, we provide customized hair treatments designed to repair and nourish damaged strands. Whether you need hydration, strengthening, or a scalp detox, book an appointment at our Södermalm salon and give your hair the care it deserves!`,
      },
      {
        image: "s6",
        category: "Beauty & Grooming",
        author: "TeamEtt",
        title:
          "Salon vs. DIY: When to Visit a Professional for Your Hair & Nails",
        description:
          "Know when to DIY and when to trust a professional stylist.",
        // Full text pulled from blogPostPage.en[5]
        fullText: `DIY beauty treatments can be fun and cost-effective, but some things are best left to professionals. At TeamEtt beauty salon in Södermalm, Stockholm, we’ve seen many DIY mishaps, from uneven haircuts to damaged nails, that require professional correction.

Home haircuts often lead to uneven lengths, and box dyes can result in unexpected colors that are difficult to fix. Similarly, DIY manicures might not last as long as professional gel or acrylic applications. While at-home beauty routines are great for maintenance, major transformations like hair coloring, haircuts, and complex nail designs are best done by a professional.

Visiting a salon ensures you receive high-quality products, expert techniques, and long-lasting results. Our stylists at TeamEtt use advanced tools and techniques to help you achieve salon-perfect hair and nails. Book an appointment with us in Södermalm, Stockholm, and enjoy the luxury of professional beauty care!`,
      },
    ],
  },
};

export const videoContent = {
  en: {
    sectionTitle: "— Our Vision",
    title: "TeamEtt Beauty Salon",
    subtitle: "Premium Hair & Styling Services",
    description:
      "Discover the ultimate experience at TeamEtt, where we blend style, comfort, and expertise to bring out the best in every client. Our dedicated stylists, colorists, and beauty professionals ensure a customized approach, tailored to your individual needs. Step into our salon and step out with renewed confidence.",
  },
  sv: {
    sectionTitle: "— Vår Erfarenhet",
    title: "TeamEtt Skönhetssalong",
    subtitle: "Upptäck Konsten att Styla",
    description:
      "På TeamEtt förvandlar vår passion för skönhet och styling varje besök till en oförglömlig upplevelse. Se vår utvalda video och upptäck vad som gör vår salong unik.",
  },
};

// textConstants.js (or wherever you keep your text constants)
export const servicesHome2Content = {
  en: {
    sectionTitle: "— Indulge in Perfection",
    title: "Flawless Nails, Timeless Elegance",
    subtitle: "with the elegance and allure of nails",
    services: [
      {
        title: "VERSATILE STYLING",
        description:
          "From elegant French tips to bold nail art, customize your nails to match your personality and style.",
      },
      {
        title: "PRECISION & BEAUTY",
        description:
          "Our professional nail technicians ensure flawless application with high-quality materials and expert craftsmanship.",
      },
      {
        title: "LONG-LASTING IMPACT",
        description:
          "With durable finishes and premium polishes, your nails will shine longer, leaving a lasting impression.",
      },
    ],
  },
  sv: {
    sectionTitle: "— Unna dig perfektion",
    title: "Perfekta naglar, tidlös elegans",
    subtitle: "med naglarnas elegans och dragningskraft",
    services: [
      {
        title: "MÅNGSIDIG STYLING",
        description:
          "Från eleganta franska toppar till djärv nagelkonst, anpassa dina naglar efter din personlighet och stil.",
      },
      {
        title: "PRECISION & SKÖNHET",
        description:
          "Våra professionella nageltekniker säkerställer en felfri applicering med högkvalitativa material och expertis.",
      },
      {
        title: "LÅNGVARIG GLANS",
        description:
          "Med hållbara ytskikt och premiumpolish kommer dina naglar att glänsa längre och göra intryck.",
      },
    ],
  },
};
