import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useLanguage } from "../../constants/LanguageContext";
import { galleryContent } from "../../constants/textConstants";
import Hero from "../../components/homeComponents/Hero";

// Fixed thumbnail path
const thumbnailIcon = "/assets/1.svg";

const imagesHeaps = [
  {
    images: [
      "/assets/hairImages/2.webp",
      "/assets/hairImages/3.webp",
      "/assets/hairImages/4.webp",
      "/assets/hairImages/5.webp",
      "/assets/hairImages/7.webp",
      "/assets/hairImages/9.webp",
      "/assets/hairImages/10.webp",
      "/assets/hairImages/11.webp",
      "/assets/hairImages/12.webp",
      "/assets/hairImages/13.webp",
    ],
    category: "Women's Hairstyles",
  },
  {
    images: [
      "/assets/men/44.webp",
      "/assets/men/1.webp",
      "/assets/men/2.webp",
      "/assets/men/3.webp",
      "/assets/men/22.webp",
      "/assets/men/33.webp",
      "/assets/men/h8.webp",
    ],
    category: "Men's Hairstyles",
  },
  {
    images: [
      "/assets/salon/1.webp",
      "/assets/salon/2.webp",
      "/assets/salon/10.webp",
      "/assets/salon/7.webp",
      "/assets/salon/8.webp",
      "/assets/salon/IMG_6700.webp",
      "/assets/salon/9.webp",
      "/assets/salon/salon.webp",
    ],
    category: "Salon Interior",
  },
  {
    images: [
      "/assets/nails/1.webp",
      "/assets/nails/2.webp",
      "/assets/nails/3.webp",
      "/assets/nails/4.webp",
      "/assets/nails/8.webp",
      "/assets/nails/7.webp",
      "/assets/nails/5.webp",
      "/assets/nails/11.webp",
    ],
    category: "Nail Art",
  },
];

const LAYOUT_CONFIG = {
  womens: {
    columns: 3,
    rowHeight: "140px",
    spans: [
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 2 },
      { colSpan: 2, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
    ],
  },
  men: {
    columns: 3,
    rowHeight: "140px",
    spans: [
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 2, rowSpan: 1 },
      { colSpan: 1, rowSpan: 2 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
    ],
  },
  salon: {
    columns: 3,
    rowHeight: "140px",
    spans: [
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 2, rowSpan: 1 },
      { colSpan: 1, rowSpan: 2 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
    ],
  },
  nails: {
    columns: 3,
    rowHeight: "140px",
    spans: [
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 2, rowSpan: 1 },
      { colSpan: 1, rowSpan: 2 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
    ],
  },
};

/**
 * A hook to watch if an element is in view, for lazy loading.
 */
function useInView(options = {}) {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const node = ref.current;
    if (!node) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.unobserve(node);
      }
    }, options);

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [ref, options]);

  return [ref, inView];
}

/**
 * The "card" that lazily loads the image,
 * then fades it in one by one.
 */
function CollageImage({ img, colSpan, rowSpan }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.1 });

  return (
    <Box
      ref={ref}
      sx={{
        gridColumn: `span ${colSpan}`,
        gridRow: `span ${rowSpan}`,
        position: "relative",
        maxWidth: "1200px",
        height: "100%",
        overflow: "hidden",
        borderRadius: "8px",
        backgroundColor: "#f3f3f3",
        willChange: "transform",
      }}
    >
      {!hasLoaded && (
        <Box
          component="img"
          src={thumbnailIcon}
          alt="thumbnail"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: 0.5,
          }}
        />
      )}

      {inView && (
        <Box
          component="img"
          src={img}
          alt="collage"
          loading="lazy"
          decoding="async"
          onLoad={() => setHasLoaded(true)}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "transform 0.3s ease, opacity 0.6s ease",
            opacity: hasLoaded ? 1 : 0,
            "&:hover": {
              transform: "scale(1.5)",
            },
          }}
        />
      )}
    </Box>
  );
}

export default function GalleryPage() {
  const { language } = useLanguage();
  const langKey = language || "sv";
  const { sectionTitle, title, subtitle, description } =
    galleryContent[langKey] || galleryContent["sv"];

  return (
    <Box>
      <Hero />
      <Box
        id="galleryPage"
        sx={{
          padding: { xs: "20px", md: "40px" },
          maxWidth: "1200px",
          marginInline: "auto",
          textAlign: "center",
          overflow: "hidden",
          fontFamily: "'Playfair Display', serif",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "left", paddingRight: "10px" }}>
          <Typography
            variant="h6"
            sx={{
              color: "#db9d47",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {sectionTitle}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "30px", md: "54px" },
              fontFamily: "'Prata', serif",
              marginBottom: "10px",
              color: "#1C1C2E",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontSize: "24px",
              fontFamily: "'Prata', serif",
              marginBottom: "20px",
              color: "#1C1C2E",
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#555",
              lineHeight: 1.7,
              mb: 3,
              fontFamily: "'Playfair Display', serif",
              textAlign: "justify",
            }}
          >
            {description}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: 4,
          }}
        >
          {imagesHeaps.map((heap, idx) => {
            let layoutKey;
            if (heap.category === "Women's Hairstyles") layoutKey = "womens";
            else if (heap.category === "Men's Hairstyles") layoutKey = "men";
            else if (heap.category === "Salon Interior") layoutKey = "salon";
            else if (heap.category === "Nail Art") layoutKey = "nails";

            const conf = LAYOUT_CONFIG[layoutKey] || {};
            const { columns = 3, rowHeight = "140px", spans = [] } = conf;

            return (
              <Box
                key={idx}
                sx={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${columns}, 1fr)`,
                  gridAutoRows: rowHeight,
                  gap: 2,
                }}
              >
                {heap.images.map((img, imgIndex) => {
                  const colSpan = spans[imgIndex]?.colSpan || 1;
                  const rowSpan = spans[imgIndex]?.rowSpan || 1;
                  return (
                    <CollageImage
                      key={imgIndex}
                      img={img}
                      colSpan={colSpan}
                      rowSpan={rowSpan}
                    />
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
