import { MenuItem, Select, Box } from "@mui/material";
import { useLanguage } from "../constants/LanguageContext"; // Import your context

function LanguageSwitcher() {
  const { language, switchLanguage } = useLanguage(); // Get the language and switch function from context

  const handleChange = (event) => {
    switchLanguage(event.target.value); // Use the context method to change language
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "white" }}>
      <Select
        value={language}
        onChange={handleChange}
        variant="outlined"
        size="small"
        sx={{
          color: "white",
          borderColor: "white",
          display: "flex",
          alignItems: "center",
        }}
        renderValue={() => {
          switch (language) {
            case "en":
              return (
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>EN</span>
              );
            case "sv":
              return (
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>SV</span>
              );
            default:
              return (
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>SV</span>
              ); // Default to Swedish
          }
        }}
      >
        <MenuItem value="en">
          <span
            style={{ fontSize: "16px", fontWeight: "bold", marginRight: "8px" }}
          >
            EN
          </span>{" "}
          English
        </MenuItem>
        <MenuItem value="sv">
          <span
            style={{ fontSize: "16px", fontWeight: "bold", marginRight: "8px" }}
          >
            SV
          </span>{" "}
          Svenska
        </MenuItem>
      </Select>
    </Box>
  );
}

export default LanguageSwitcher;
