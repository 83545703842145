import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useLanguage } from "../../constants/LanguageContext";
import { galleryContent } from "../../constants/textConstants";
import { useNavigate } from "react-router-dom";

const thumbnailIcon = "../../assets/1.svg";
const imagesHeaps = [
  {
    images: [
      "../../assets/salon/10.webp",
      "../../assets/hairImages/1.webp",
      "../../assets/hairImages/2.webp",
      "../../assets/men/h8.webp",
      "../../assets/hairImages/4.webp",
      "../../assets/nails/4.webp",
      "../../assets/hairImages/9.webp",
    ],
    category: "Salon Interior",
  },
];

const LAYOUT_CONFIG = {
  salon: {
    columns: 3,
    rowHeight: { xs: "140px", md: "350px" },
    spans: [
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 2, rowSpan: 1 },
      { colSpan: 1, rowSpan: 2 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
      { colSpan: 1, rowSpan: 1 },
    ],
  },
};

function useInView(options = {}) {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const node = ref.current;
    if (!node) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(node);
        }
      },
      { threshold: 0, ...options }
    );

    observer.observe(node);

    return () => {
      if (node) observer.unobserve(node);
    };
  }, [ref, options]);

  return [ref, inView];
}

function CollageImage({ img, colSpan, rowSpan }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [ref, inView] = useInView({ threshold: 0 });

  return (
    <Box
      id="gallery"
      ref={ref}
      sx={{
        gridColumn: `span ${colSpan}`,
        gridRow: `span ${rowSpan}`,
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: "8px",
        backgroundColor: "#f3f3f3",
        willChange: "transform",
      }}
    >
      {!hasLoaded && (
        <Box
          component="img"
          src={thumbnailIcon}
          alt="thumbnail"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: 0.5,
          }}
        />
      )}

      {inView && (
        <Box
          component="img"
          src={img}
          alt="collage"
          loading="lazy"
          decoding="async"
          onLoad={() => setHasLoaded(true)}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transition: "transform 0.3s ease, opacity 0.6s ease",
            opacity: hasLoaded ? 1 : 0,
            "&:hover": {
              transform: "scale(1.5)",
            },
          }}
        />
      )}
    </Box>
  );
}

export default function Gallery2() {
  const { language } = useLanguage();
  const langKey = language || "sv";
  const { sectionTitle, title, subtitle, description } =
    galleryContent[langKey] || galleryContent["sv"];
  const navigate = useNavigate();

  return (
    <Box
      id="gallery2"
      sx={{
        padding: { xs: "20px", md: "40px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
        overflow: "hidden",
        fontFamily: "'Playfair Display', serif",
      }}
    >
      {/* Section Title */}
      <Box sx={{ width: "100%", textAlign: "left", paddingRight: "10px" }}>
        <Typography
          variant="h6"
          sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
        >
          {sectionTitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "30px", md: "54px" },
            fontFamily: "'Prata', serif",
            marginBottom: "10px",
            color: "#1C1C2E",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "24px",
            fontFamily: "'Prata', serif",
            marginBottom: "20px",
            color: "#1C1C2E",
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#555",
            lineHeight: 1.7,
            mb: 3,
            fontFamily: "'Playfair Display', serif",
            textAlign: "left",
          }}
        >
          {description}
        </Typography>
      </Box>

      {/* Gallery Layout */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${LAYOUT_CONFIG.salon.columns}, 1fr)`,
          gridAutoRows: LAYOUT_CONFIG.salon.rowHeight,
          gap: 2,
          justifyContent: "center",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        {imagesHeaps[0].images.map((img, imgIndex) => {
          const colSpan = LAYOUT_CONFIG.salon.spans[imgIndex]?.colSpan || 1;
          const rowSpan = LAYOUT_CONFIG.salon.spans[imgIndex]?.rowSpan || 1;
          return (
            <CollageImage
              key={imgIndex}
              img={img}
              colSpan={colSpan}
              rowSpan={rowSpan}
            />
          );
        })}
      </Box>

      {/* "Check More" Button */}
      <Box sx={{ textAlign: "center", marginTop: "30px" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#db9d47",
            color: "#fff",
            padding: "10px 20px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "8px",
            textTransform: "none",
            "&:hover": { backgroundColor: "#b8843d" },
          }}
          onClick={() => navigate("/gallery")}
        >
          Check More
        </Button>
      </Box>
    </Box>
  );
}
