import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Footer from "./pages/Footer/Footer";
import Blog from "./pages/Blog/Blog";
import GalleryPage from "./pages/Gallery/GalleryPage";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
