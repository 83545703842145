import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import aboutUsImage from "../../assets/salon/about.avif";
import { useLanguage } from "../../constants/LanguageContext";
import { aboutUsContent } from "../../constants/textConstants";

// Reusable button styles
const buttonStyles = {
  backgroundColor: "#db9d47",
  color: "#1C1C2E",
  fontWeight: "bold",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#E3C187",
  },
};

const AboutUsHome = () => {
  const { language } = useLanguage();
  const { sectionTitle, title, text, buttonText } =
    aboutUsContent[language] || aboutUsContent["sv"];

  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);

  // Intersection Observer for scroll-triggered animation
  useEffect(() => {
    const element = aboutRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once triggered
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );

    observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, []);

  return (
    <Box
      id="about"
      ref={aboutRef}
      sx={{
        maxWidth: "1200px",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        padding: "50px 10%",
        backgroundColor: "white",
        margin: "auto",
        // Fade + slight slide-in for the entire container
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: {
          xs: "opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)",
          md: "opacity 1s ease-in-out, transform 1s ease-in-out",
        },
        willChange: "opacity, transform",
      }}
    >
      {/* IMAGE + GOLD BORDER */}
      <Box
        sx={{
          position: "relative",
          width: { xs: "100%", md: "45%" },
          maxWidth: "500px",
        }}
      >
        {/*
          GOLD BORDER behind the image:
          - Same final size as the image.
          - Offset so the top-left corner is visible behind the image.
          - Scales from 0 to 1 for the "slow reveal."
          - No border radius (square corners).
        */}
        <Box
          sx={{
            position: "absolute",
            top: "-20px",
            left: "-20px",
            // Make the border exactly the same final size as the image
            width: "calc(100% + 5px)",
            height: "calc(100% + 5px)",
            border: "3px solid #db9d47",
            zIndex: 1,
            // Animate scale from 0 -> 1
            transformOrigin: "top left",
            transform: isVisible ? "scale(1)" : "scale(0)",
            transition: "transform 1.2s ease",
          }}
        />
        <Box
          component="img"
          src={aboutUsImage}
          alt="About Us - Salon Interior"
          loading="lazy"
          srcSet={`${aboutUsImage}?w=500&format=avif 500w,
                   ${aboutUsImage}?w=800&format=avif 800w`}
          sizes="(max-width: 600px) 500px, 800px"
          sx={{
            width: "100%",
            height: "auto",
            display: "block",
            position: "relative",
            zIndex: 2, // ensure the image is above the border
            // No borderRadius to match the reference picture
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>

      {/* TEXT CONTENT */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          textAlign: "left",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
        >
          {sectionTitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "30px", md: "54px" },
            fontFamily: "'Prata', serif",
            marginBottom: "10px",
            color: "#1C1C2E",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          textAlign="left"
          sx={{ color: "#555", lineHeight: 1.7, mb: 3 }}
        >
          {text}
        </Typography>
        <a href="/contact" style={{ textDecoration: "none" }}>
          <Button variant="contained" sx={buttonStyles}>
            {buttonText}
          </Button>
        </a>
      </Box>
    </Box>
  );
};

export default AboutUsHome;
