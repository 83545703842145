import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { hero } from "../../constants/textConstants";

import background from "../../assets/salon/salon.avif";
import { useLanguage } from "../../constants/LanguageContext";

function Hero() {
  const { language } = useLanguage(); // Get current language from context

  const { heroTitle, heroDescription, contactUsButtonText } =
    hero[language] || hero["sv"];

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        transition: "background-image 0.5s ease-in-out",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "white",
        padding: { xs: "20px", md: "0" },
        marginTop: "-110px",
      }}
    >
      {/* Dark Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay
          zIndex: 1,
        }}
      />
      {/* Hero Content */}
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          maxWidth: "850px",
          textAlign: "center",
          paddingX: { xs: "10px", md: "0" }, // Add padding on small screens
        }}
      >
        {/* Small Header with Decorative Line */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: "600",
            color: "#db9d47",
            fontFamily: `"Prata", serif`,
            textTransform: "uppercase",
            fontSize: { xs: "18px", md: "25px" },
            letterSpacing: "1px",
            position: "relative",
            display: "inline-block",
            marginBottom: "10px",
            "&::before, &::after": {
              content: '""',
              display: "block",
              width: "30px",
              height: "2px",
              backgroundColor: "#E3C187",
              position: "absolute",
              top: "50%",
            },
            "&::before": { left: "-50px" },
            "&::after": { right: "-50px" },
          }}
        >
          {/* {featureItems} */}
          Team Ett
        </Typography>

        {/* Main Title with Prata Font (Responsive) */}
        <Typography
          variant="h1"
          sx={{
            fontWeight: "400",
            fontFamily: `"Prata", serif`,
            fontSize: { xs: "40px", md: "54px" }, // Reduce size for XS
            marginBottom: "15px",
            lineHeight: "1.3",
          }}
        >
          {heroTitle}
        </Typography>

        {/* Subtitle (Centered and Properly Aligned) */}
        <Typography
          variant="body1"
          sx={{
            margin: "0 auto 25px auto",
            maxWidth: "750px",
            fontSize: { xs: "16px", md: "18px" },
            lineHeight: "1.5",
            fontFamily: `"Lora", serif`, // Updated font recommendation
            color: "#ddd",
          }}
        >
          {heroDescription}
        </Typography>
        {/* Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "#1C1C2E",
            padding: { xs: "10px 25px", md: "12px 35px" }, // Reduce padding for XS
            fontWeight: "bold",
            fontSize: { xs: "14px", md: "16px" }, // Reduce button font size
            fontFamily: `"Prata", serif`,
            textTransform: "uppercase",
            borderRadius: "4px",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#db9d47",
              color: "white",
            },
          }}
          onClick={() =>
            window.open(
              "https://www.bokadirekt.se/places/salong-team-ett-sodermalm-60828",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          {contactUsButtonText}
        </Button>
      </Container>
      {/* Bottom Contact Info */}
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          bottom: "30px",
          width: "90%",
          textAlign: "center",
          zIndex: 2,
          borderTop: "0.2px solid grey",
          paddingTop: "20px",
          fontFamily: `"Prata", serif`,
        }}
      >
        {/* Address */}
        <Box sx={{ width: "100%", fontFamily: `"Prata", serif` }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "#db9d47",
              borderRight: { xs: "none", sm: "1px solid grey" }, // No border on XS for better readability
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: `"Prata", serif`,
            }}
          >
            ADDRESS
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: {
                xs: "12px",
                md: "14px",
                fontFamily: `"Prata", serif`,
              },
            }}
          >
            Magnus Ladulåsgatan 61
          </Typography>
        </Box>

        {/* Phone */}
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "#db9d47",
              borderRight: {
                xs: "none",
                sm: "1px solid grey",
                fontFamily: `"Prata", serif`,
              },
              fontSize: {
                xs: "12px",
                md: "14px",
                fontFamily: `"Prata", serif`,
              },
            }}
          >
            PHONE
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: {
                xs: "12px",
                md: "14px",
                fontFamily: `"Prata", serif`,
              },
            }}
          >
            0736530910
          </Typography>
        </Box>

        {/* Email */}
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              color: "#db9d47",
              fontSize: {
                xs: "12px",
                md: "14px",
                fontFamily: `"Prata", serif`,
              },
            }}
          >
            EMAIL
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: {
                xs: "12px",
                md: "14px",
                fontFamily: `"Prata", serif`,
              },
            }}
          >
            info@team-ett.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
