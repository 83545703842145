// import React from "react";
// import { Box, Typography, Link } from "@mui/material";
// import {
//   FaMapMarkerAlt,
//   FaPhoneAlt,
//   FaEnvelope,
//   FaFacebook,
//   FaInstagram,
// } from "react-icons/fa";
// import { useLocation, useNavigate } from "react-router-dom";
// import { scroller } from "react-scroll";
// import footerBackground from "../../assets/salon.jpg";
// import logo from "../../assets/logoTransparent.png";
// import bukaIcon from "../../assets/buka.png";

// // Reusable style for all Links (zoom + color change on hover)
// const zoomLinkStyle = {
//   display: "inline-block",
//   color: "white",
//   transition: "transform 0.3s ease, color 0.3s ease",
//   "&:hover": {
//     transform: "scale(1.2)",
//     color: "#db9d47",
//   },
// };

// // Scroll to top (used for Home link)
// const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

// export default function Footer() {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Similar to handleNavigation in Navbar
//   const handleFooterNav = (e, section) => {
//     e.preventDefault();
//     if (section === "home") {
//       // Go to homepage, then scroll top
//       navigate("/");
//       setTimeout(() => scrollToTop(), 100);
//       return;
//     }

//     // For other sections: if not on "/", navigate there with scrollTo param
//     if (location.pathname !== "/") {
//       navigate(`/?scrollTo=${section}`);
//     } else {
//       // Already on "/", so just scroll
//       scroller.scrollTo(section, {
//         smooth: true,
//         duration: 500,
//         offset: -80,
//       });
//     }
//   };

//   const handleContactClick = (e) => {
//     e.preventDefault();
//     navigate("/contact");
//   };

//   return (
//     <Box
//       sx={{
//         backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${footerBackground})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         color: "white",
//         padding: { xs: "30px 5%", md: "50px 10%", lg: "60px 15%" },
//       }}
//     >
//       <Box
//         sx={{
//           display: "grid",
//           gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
//           gap: { xs: "40px", md: "30px" },
//           textAlign: { xs: "left", md: "left" },
//         }}
//       >
//         {/* Salon Info */}
//         <Box>
//           <Typography
//             variant="h5"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontWeight: "bold",
//               mb: 2,
//               fontSize: { xs: "24px", md: "28px" },
//             }}
//           >
//             TeamEtt
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               mb: 2,
//               color: "#ccc",
//               fontSize: "16px",
//             }}
//           >
//             Premium beauty salon & salon services tailored for your perfect
//             style.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontWeight: "bold",
//               mt: 2,
//               fontSize: "18px",
//             }}
//           >
//             Address:
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontSize: "16px",
//             }}
//           >
//             <FaMapMarkerAlt style={{ marginRight: 5, color: "#c59d5f" }} />
//             Magnus Ladulåsgatan 61, 118 27 Stockholm
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontWeight: "bold",
//               mt: 2,
//               fontSize: "18px",
//             }}
//           >
//             Email:
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontSize: "16px",
//             }}
//           >
//             <FaEnvelope style={{ marginRight: 5, color: "#c59d5f" }} />
//             <Link
//               href="mailto:info@teamett.com"
//               underline="none"
//               sx={zoomLinkStyle}
//             >
//               info@teamett.com
//             </Link>
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontWeight: "bold",
//               mt: 2,
//               fontSize: "18px",
//             }}
//           >
//             Phone:
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontSize: "16px",
//             }}
//           >
//             <FaPhoneAlt style={{ marginRight: 5, color: "#c59d5f" }} />
//             <Link href="tel:+46736530910" underline="none" sx={zoomLinkStyle}>
//               073-653 09 10
//             </Link>
//           </Typography>
//         </Box>

//         {/* Navigation Menu + Logo */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: { xs: "space-around", md: "space-between" },
//           }}
//         >
//           {/* Menu Items */}
//           <Box>
//             <Typography
//               variant="h6"
//               sx={{
//                 fontFamily: "'Playfair Display', serif",
//                 fontWeight: "bold",
//                 mb: 2,
//                 fontSize: { xs: "20px", md: "24px" },
//               }}
//             >
//               Menu
//             </Typography>
//             {["Home", "About", "Price", "Services", "Gallery", "Blog"].map(
//               (item, index) => (
//                 <Typography
//                   key={index}
//                   variant="body2"
//                   sx={{
//                     mb: 1,
//                     fontFamily: "'Playfair Display', serif",
//                     fontSize: "16px",
//                   }}
//                 >
//                   <Link
//                     href=""
//                     underline="none"
//                     sx={zoomLinkStyle}
//                     onClick={(e) => handleFooterNav(e, item.toLowerCase())}
//                   >
//                     {item}
//                   </Link>
//                 </Typography>
//               )
//             )}

//             {/* Contact Page Link */}
//             <Typography
//               variant="body2"
//               sx={{
//                 mb: 1,
//                 fontFamily: "'Playfair Display', serif",
//                 fontSize: "16px",
//               }}
//             >
//               <Link
//                 href=""
//                 underline="none"
//                 sx={zoomLinkStyle}
//                 onClick={handleContactClick}
//               >
//                 Contact
//               </Link>
//             </Typography>
//           </Box>

//           {/* Logo with zoom on hover */}
//           <Box
//             component="img"
//             src={logo}
//             alt="TeamEtt Logo"
//             sx={{
//               width: "auto",
//               height: { xs: "150px", md: "200px" }, // Slightly smaller on mobile
//               transition: "transform 0.3s ease",
//               "&:hover": {
//                 transform: "scale(1.1)",
//               },
//             }}
//           />
//         </Box>

//         {/* Opening Hours */}
//         <Box>
//           <Typography
//             variant="h6"
//             sx={{
//               fontFamily: "'Playfair Display', serif",
//               fontWeight: "bold",
//               mb: 2,
//               fontSize: { xs: "20px", md: "24px" },
//             }}
//           >
//             Opening Hours
//           </Typography>
//           {[
//             { day: "MON", time: "10:00 - 19:00" },
//             { day: "TUE", time: "10:00 - 19:00" },
//             { day: "WED", time: "10:00 - 19:00" },
//             { day: "THU", time: "10:00 - 19:00" },
//             { day: "FRI", time: "10:00 - 19:00" },
//             { day: "SAT", time: "10:00 - 18:00" },
//             { day: "SUN", time: "CLOSED", closed: true },
//           ].map((item, index) => (
//             <Box
//               key={index}
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 borderBottom: "1px dotted rgba(255, 255, 255, 0.5)",
//                 padding: "8px 0",
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 sx={{
//                   fontFamily: "'Playfair Display', serif",
//                   fontSize: "16px",
//                 }}
//               >
//                 {item.day}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 sx={{
//                   fontFamily: "'Playfair Display', serif",
//                   fontSize: "16px",
//                   color: item.closed ? "red" : "white",
//                 }}
//               >
//                 {item.time}
//               </Typography>
//             </Box>
//           ))}
//         </Box>
//       </Box>

//       {/* Social Media Links */}
//       <Box sx={{ mt: 4, textAlign: "center" }}>
//         <Typography
//           variant="h6"
//           sx={{
//             fontFamily: "'Playfair Display', serif",
//             fontWeight: "bold",
//             mb: 1,
//             fontSize: { xs: "20px", md: "24px" },
//           }}
//         >
//           Follow Us
//         </Typography>
//         <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
//           <Link
//             href="https://www.facebook.com/teamett"
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ fontSize: "24px", ...zoomLinkStyle }}
//           >
//             <FaFacebook />
//           </Link>
//           <Link
//             href="https://www.instagram.com/teamett"
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ fontSize: "24px", ...zoomLinkStyle }}
//           >
//             <FaInstagram />
//           </Link>
//           <Link
//             href="https://www.bokadirekt.se/places/salong-team-ett-60828"
//             target="_blank"
//             rel="noopener noreferrer"
//             sx={{ fontSize: "24px", ...zoomLinkStyle }}
//           >
//             <Box
//               component="img"
//               src={bukaIcon}
//               alt="Buka Icon"
//               sx={{ width: "24px", height: "24px" }}
//             />
//           </Link>
//         </Box>
//       </Box>

//       {/* Copyright */}
//       <Typography
//         variant="body2"
//         sx={{
//           mt: 4,
//           color: "#ccc",
//           textAlign: "center",
//           fontFamily: "'Playfair Display', serif",
//           fontSize: "16px",
//         }}
//       >
//         © TeamEtt |{" "}
//         <Link
//           href="https://samoteev.dev/"
//           target="_blank"
//           rel="noopener noreferrer"
//           underline="none"
//           sx={{ ...zoomLinkStyle, color: "inherit" }}
//         >
//           Designed by AS
//         </Link>{" "}
//         | 2025 Stockholm
//       </Typography>
//     </Box>
//   );
// }

import React from "react";
import { Box, Typography, Link } from "@mui/material";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import footerBackground from "../../assets/salon.jpg";
import logo from "../../assets/logoTransparent.png";
import bukaIcon from "../../assets/buka.png";

// Reusable style for all Links (zoom + color change on hover)
const zoomLinkStyle = {
  display: "inline-block",
  color: "white",
  transition: "transform 0.3s ease, color 0.3s ease",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#db9d47",
  },
};

// Scroll to top (used for Home link)
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// eslint-disable-next-line no-unused-vars
const handleFooterNav = (e, section) => {
  e.preventDefault();
  // This function handles navigation for the footer links.
  // If section is "home", navigate to home and scroll to top.
  // Otherwise, navigate or scroll based on current location.
  // (Implementation is in the component below.)
};

// eslint-disable-next-line no-unused-vars
const handleContactClick = (e) => {
  e.preventDefault();
  // This function handles the contact link click.
  // (Implementation is in the component below.)
};

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const _handleFooterNav = (e, section) => {
    e.preventDefault();
    if (section === "home") {
      // Go to homepage, then scroll top
      navigate("/");
      setTimeout(() => scrollToTop(), 100);
      return;
    }

    // For other sections: if not on "/", navigate there with scrollTo param
    if (location.pathname !== "/") {
      navigate(`/?scrollTo=${section}`);
    } else {
      // Already on "/", so just scroll
      scroller.scrollTo(section, {
        smooth: true,
        duration: 500,
        offset: -80,
      });
    }
  };

  const _handleContactClick = (e) => {
    e.preventDefault();
    navigate("/contact");
  };

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${footerBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        padding: { xs: "30px 5%", md: "50px 10%", lg: "60px 15%" },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
          gap: { xs: "40px", md: "30px" },
          textAlign: { xs: "left", md: "left" },
        }}
      >
        {/* Salon Info */}
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mb: 2,
              fontSize: { xs: "24px", md: "28px" },
            }}
          >
            TeamEtt
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Playfair Display', serif",
              mb: 2,
              color: "#ccc",
              fontSize: "16px",
            }}
          >
            Premium beauty salon & salon services tailored for your perfect
            style.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Address:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: "16px",
            }}
          >
            <FaMapMarkerAlt style={{ marginRight: 5, color: "#c59d5f" }} />
            Magnus Ladulåsgatan 61, 118 27 Stockholm
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Email:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: "16px",
            }}
          >
            <FaEnvelope style={{ marginRight: 5, color: "#c59d5f" }} />
            <Link
              component="a"
              href="mailto:info@teamett.com"
              underline="none"
              sx={zoomLinkStyle}
            >
              info@team-ett.com
            </Link>
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mt: 2,
              fontSize: "18px",
            }}
          >
            Phone:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontSize: "16px",
            }}
          >
            <FaPhoneAlt style={{ marginRight: 5, color: "#c59d5f" }} />
            <Link
              component="a"
              href="tel:+46736530910"
              underline="none"
              sx={zoomLinkStyle}
            >
              073-653 09 10
            </Link>
          </Typography>
        </Box>

        {/* Navigation Menu + Logo */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-around", md: "space-between" },
          }}
        >
          {/* Menu Items */}
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Playfair Display', serif",
                fontWeight: "bold",
                mb: 2,
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Menu
            </Typography>
            {["Home", "About", "Price", "Services", "Gallery", "Blog"].map(
              (item, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    mb: 1,
                    fontFamily: "'Playfair Display', serif",
                    fontSize: "16px",
                  }}
                >
                  <Link
                    component="a"
                    href={
                      item.toLowerCase() === "home"
                        ? "/"
                        : `/?scrollTo=${item.toLowerCase()}`
                    }
                    underline="none"
                    sx={zoomLinkStyle}
                    onClick={(e) => _handleFooterNav(e, item.toLowerCase())}
                  >
                    {item}
                  </Link>
                </Typography>
              )
            )}

            {/* Contact Page Link */}
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                fontFamily: "'Playfair Display', serif",
                fontSize: "16px",
              }}
            >
              <Link
                component="a"
                href="/contact"
                underline="none"
                sx={zoomLinkStyle}
                onClick={_handleContactClick}
              >
                Contact
              </Link>
            </Typography>
          </Box>

          {/* Logo with zoom on hover */}
          <Box
            component="img"
            src={logo}
            alt="TeamEtt Logo"
            sx={{
              width: "auto",
              height: { xs: "150px", md: "200px" },
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          />
        </Box>

        {/* Opening Hours */}
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Playfair Display', serif",
              fontWeight: "bold",
              mb: 2,
              fontSize: { xs: "20px", md: "24px" },
            }}
          >
            Opening Hours
          </Typography>
          {[
            { day: "MON", time: "10:00 - 19:00" },
            { day: "TUE", time: "10:00 - 19:00" },
            { day: "WED", time: "10:00 - 19:00" },
            { day: "THU", time: "10:00 - 19:00" },
            { day: "FRI", time: "10:00 - 19:00" },
            { day: "SAT", time: "10:00 - 18:00" },
            { day: "SUN", time: "CLOSED", closed: true },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px dotted rgba(255, 255, 255, 0.5)",
                padding: "8px 0",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Playfair Display', serif",
                  fontSize: "16px",
                }}
              >
                {item.day}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Playfair Display', serif",
                  fontSize: "16px",
                  color: item.closed ? "red" : "white",
                }}
              >
                {item.time}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Social Media Links */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "'Playfair Display', serif",
            fontWeight: "bold",
            mb: 1,
            fontSize: { xs: "20px", md: "24px" },
          }}
        >
          Follow Us
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Link
            component="a"
            href="https://www.facebook.com/TeamEtt.Stockholm/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontSize: "24px", ...zoomLinkStyle }}
          >
            <FaFacebook />
          </Link>
          <Link
            component="a"
            href="https://www.instagram.com/team_ett_stockholm/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontSize: "24px", ...zoomLinkStyle }}
          >
            <FaInstagram />
          </Link>
          <Link
            component="a"
            href="https://www.bokadirekt.se/places/salong-team-ett-60828"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontSize: "24px", ...zoomLinkStyle }}
          >
            <Box
              component="img"
              src={bukaIcon}
              alt="Boka Direkt Icon"
              sx={{ width: "24px", height: "24px" }}
            />
          </Link>
        </Box>
      </Box>

      {/* Copyright */}
      <Typography
        variant="body2"
        sx={{
          mt: 4,
          color: "#ccc",
          textAlign: "center",
          fontFamily: "'Playfair Display', serif",
          fontSize: "16px",
        }}
      >
        © TeamEtt |{" "}
        <Link
          component="a"
          href="https://samoteev.dev/"
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{ ...zoomLinkStyle, color: "inherit" }}
        >
          Designed by AS
        </Link>{" "}
        | 2025 Stockholm
      </Typography>
    </Box>
  );
}
