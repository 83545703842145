import React, { useCallback, useEffect, useRef } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import { useLanguage } from "../../constants/LanguageContext";
import { kidsCornerContent } from "../../constants/textConstants";

// Import images
import g01 from "../../assets/galerySlider/01.webp";
import g1 from "../../assets/galerySlider/1.webp";
import g6 from "../../assets/galerySlider/5.webp";
import g2 from "../../assets/galerySlider/2.webp";
import g3 from "../../assets/galerySlider/3.webp";
import g4 from "../../assets/galerySlider/4.webp";
// import g5 from "../../assets/galerySlider/5.webp";

const images = [g01, g1, g2, g3, g4, g6];

const CustomSlider = React.memo(() => {
  const { language } = useLanguage();
  const langKey = language || "sv"; // Fallback to Swedish
  const { sectionTitle, title, subtitle, description } =
    kidsCornerContent[langKey] || kidsCornerContent["sv"];

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const imagesPerSlide = isXs ? 1 : isSm ? 2 : 3;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    speed: 5,
    align: "start",
    skipSnaps: false,
  });
  const autoplayInterval = useRef(null);

  const startAutoplay = useCallback(() => {
    if (autoplayInterval.current) clearInterval(autoplayInterval.current);
    autoplayInterval.current = setInterval(() => {
      if (emblaApi) emblaApi.scrollNext();
    }, 3000);
  }, [emblaApi]);

  const stopAutoplay = useCallback(() => {
    if (autoplayInterval.current) clearInterval(autoplayInterval.current);
  }, []);

  useEffect(() => {
    if (emblaApi) {
      startAutoplay();
      emblaApi.on("pointerDown", stopAutoplay);
      emblaApi.on("pointerUp", startAutoplay);
    }
    return () => {
      stopAutoplay();
      if (emblaApi) {
        emblaApi.off("pointerDown", stopAutoplay);
        emblaApi.off("pointerUp", startAutoplay);
      }
    };
  }, [emblaApi, startAutoplay, stopAutoplay]);

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        overflow: "hidden",
        position: "relative",
        padding: { xs: "20px", md: "40px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        margin: "auto",
      }}
    >
      {/* Section Title */}
      <Box
        sx={{
          width: "100%",
          textAlign: "right",
          px: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
        >
          {sectionTitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "30px", md: "54px" },
            fontFamily: "'Prata', serif",
            marginBottom: "10px",
            color: "#1C1C2E",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "'Prata', serif",
            marginBottom: "20px",
            color: "#1C1C2E",
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="body1"
          textAlign="left"
          sx={{ color: "#555", lineHeight: 1.7, mb: 3 }}
        >
          {description}
        </Typography>
      </Box>

      {/* Embla Carousel */}
      <Box sx={{ overflow: "hidden", width: "100%" }} ref={emblaRef}>
        <Box sx={{ display: "flex" }}>
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{
                flex: `0 0 calc(100% / ${imagesPerSlide})`,
                maxWidth: `calc(100% / ${imagesPerSlide})`,
                padding: { xs: "5px", sm: "10px" },
              }}
            >
              <Box
                component="img"
                src={image}
                alt={`Kids enjoying fun activities - Slide ${index + 1}`}
                loading="lazy"
                width="100%"
                height="auto"
                sx={{
                  height: { xs: "250px", sm: "300px", md: "350px" },
                  objectFit: "cover",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
});

export default CustomSlider;
