import React from "react";
import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import im1 from "../../assets/loreal.png";
import im2 from "../../assets/redken.png";
import im3 from "../../assets/wella1.png";

// Define infinite scrolling animation
const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); } /* Moves half width, looping seamlessly */
`;

function Carousel() {
  const images = [im1, im2, im3, im1, im2, im3, im1, im2, im3];

  return (
    <Box
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        marginInline: { xs: "40px", lg: "100px" },
        overflow: "hidden",
        backgroundColor: "white",
        padding: { xs: "40px 10px", md: "80px 50px", lg: "100px" }, // Responsive padding
        position: "relative",
        whiteSpace: "nowrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          animation: `${scroll} 25s linear infinite`, // Continuous smooth scrolling
          width: "200%", // Ensures smooth looping
        }}
      >
        {/* Repeat items twice for a seamless loop */}
        {[...images, ...images].map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`Brand ${index + 1}`}
            sx={{
              height: { xs: "50px", sm: "70px", md: "90px", lg: "100px" }, // Responsive height
              width: "auto",
              marginRight: { xs: "30px", sm: "50px", md: "80px", lg: "100px" }, // Responsive spacing
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Carousel;
