// import React, { useEffect, useRef, useState, memo } from "react";
// import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
// import { useLanguage } from "../../constants/LanguageContext";
// import { servicesHome2Content } from "../../constants/textConstants";

// // Import your images
// import nail1 from "../../assets/nails/5.avif";
// import nail2 from "../../assets/nails/2.avif";
// import nail3 from "../../assets/nails/4.avif";

// // Reusable service item styles
// const serviceItemStyles = {
//   backgroundColor: "#ebddc3",
//   padding: { xs: "50px 20px 30px", md: "60px 30px 30px" },
//   borderRadius: "10px",
//   textAlign: "center",
//   position: "relative",
//   overflow: "visible",
//   opacity: 0,
//   transform: "translateY(20px)",
//   transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
// };

// const ServiceItem = memo(({ service, isXs, isVisible }) => {
//   return (
//     <Box
//       sx={{
//         ...serviceItemStyles,
//         opacity: isVisible ? 1 : 0,
//         transform: isVisible ? "translateY(0)" : "translateY(20px)",
//       }}
//     >
//       {/* Circular Image */}
//       <Box
//         component="img"
//         src={service.image}
//         alt={service.title}
//         loading="lazy"
//         srcSet={`${service.image}?w=200&format=avif 200w,
//                  ${service.image}?w=400&format=avif 400w`}
//         sizes="(max-width: 600px) 200px, 400px"
//         sx={{
//           width: { xs: "100px", sm: "120px" },
//           height: { xs: "130px", sm: "160px" },
//           borderRadius: "50% / 65%",
//           objectFit: "cover",
//           position: "absolute",
//           top: isXs ? "-40px" : "-50px",
//           left: "50%",
//           transform: "translateX(-50%)",
//           border: "5px solid #F5EDE3",
//         }}
//       />
//       <Typography
//         variant="h6"
//         sx={{
//           fontWeight: "bold",
//           marginTop: isXs ? "70px" : "80px",
//           marginBottom: "10px",
//           fontFamily: "serif",
//           fontSize: { xs: "16px", md: "20px" },
//         }}
//       >
//         {service.title}
//       </Typography>
//       <Typography
//         variant="body1"
//         textAlign="left"
//         sx={{
//           color: "#666",
//           fontSize: { xs: "14px", md: "16px" },
//         }}
//       >
//         {service.description}
//       </Typography>
//     </Box>
//   );
// });

// const ServiceHome2 = () => {
//   const theme = useTheme();
//   const isXs = useMediaQuery(theme.breakpoints.down("sm"));

//   // Retrieve language-specific text from constants
//   const { language } = useLanguage();
//   const {
//     sectionTitle,
//     title,
//     subtitle,
//     services: textServices,
//   } = servicesHome2Content[language] || servicesHome2Content["sv"];

//   // Combine textServices with local images
//   const serviceImages = [nail1, nail2, nail3];
//   const services = textServices.map((service, index) => ({
//     ...service,
//     image: serviceImages[index],
//   }));

//   // State to track visibility of each service item (for animations)
//   const [isVisibleArray, setIsVisibleArray] = useState(
//     Array(services.length).fill(false)
//   );
//   const sectionRef = useRef(null);

//   // Intersection Observer for scroll-triggered animation
//   useEffect(() => {
//     const element = sectionRef.current;
//     if (!element) return;

//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           services.forEach((_, index) => {
//             setTimeout(() => {
//               setIsVisibleArray((prev) => {
//                 const newArray = [...prev];
//                 newArray[index] = true;
//                 return newArray;
//               });
//             }, index * 300); // 300ms delay between items
//           });
//           observer.unobserve(entry.target);
//         }
//       },
//       {
//         root: null,
//         rootMargin: "0px",
//         threshold: 0.3,
//       }
//     );

//     observer.observe(element);
//     return () => {
//       if (element) observer.unobserve(element);
//     };
//   }, [services]);

//   return (
//     <Box
//       ref={sectionRef}
//       sx={{
//         maxWidth: "1200px",
//         margin: "auto",
//         textAlign: "center",
//         padding: { xs: "30px 15px", md: "50px 20px" },
//         borderRadius: "10px",
//         marginBottom: "50px",
//       }}
//     >
//       {/* Section Title */}
//       <Typography
//         variant="h6"
//         sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
//       >
//         {sectionTitle}
//       </Typography>

//       <Typography
//         variant="h4"
//         sx={{
//           fontWeight: "bold",
//           fontSize: { xs: "30px", md: "54px" },
//           fontFamily: "'Prata', serif",
//           marginBottom: "10px",
//           color: "#1C1C2E",
//         }}
//       >
//         {title}
//       </Typography>
//       <Typography
//         variant="h5"
//         sx={{
//           fontWeight: "bold",
//           fontSize: "24px",
//           fontFamily: "'Prata', serif",
//           marginBottom: "100px",
//           color: "#1C1C2E",
//         }}
//       >
//         {subtitle}
//       </Typography>

//       {/* Services Section */}
//       <Box
//         sx={{
//           display: "grid",
//           gridTemplateColumns: {
//             xs: "1fr", // 1 column on extra-small screens
//             sm: "1fr 1fr", // 2 columns on small screens
//             md: "1fr 1fr 1fr", // 3 columns on medium and up
//           },
//           gap: { xs: "20px", md: "30px" },
//           alignItems: "center",
//         }}
//       >
//         {services.map((service, index) => (
//           <ServiceItem
//             key={index}
//             service={service}
//             isXs={isXs}
//             isVisible={isVisibleArray[index]}
//           />
//         ))}
//       </Box>
//     </Box>
//   );
// };

// export default memo(ServiceHome2);

import React, { useEffect, useRef, useState, memo, useMemo } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useLanguage } from "../../constants/LanguageContext";
import { servicesHome2Content } from "../../constants/textConstants";

// Import images
import nail1 from "../../assets/nails/5.avif";
import nail2 from "../../assets/nails/2.avif";
import nail3 from "../../assets/nails/4.avif";

// Service item styles
const serviceItemStyles = {
  backgroundColor: "#ebddc3",
  padding: { xs: "50px 20px 30px", md: "60px 30px 30px" },
  borderRadius: "10px",
  textAlign: "center",
  position: "relative",
  overflow: "visible",
  opacity: 0,
  transform: "translateY(20px)",
  transition: "opacity 0.8s ease-in-out, transform 0.8s ease-in-out",
};

const ServiceItem = memo(({ service, isXs, isVisible }) => (
  <Box
    sx={{
      ...serviceItemStyles,
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateY(0)" : "translateY(20px)",
    }}
  >
    {/* Circular Image */}
    <Box
      component="img"
      src={service.image}
      alt={service.title}
      loading="lazy"
      srcSet={`${service.image}?w=200&format=avif 200w,
               ${service.image}?w=400&format=avif 400w`}
      sizes="(max-width: 600px) 200px, 400px"
      sx={{
        width: { xs: "100px", sm: "120px" },
        height: { xs: "130px", sm: "160px" },
        borderRadius: "50% / 65%",
        objectFit: "cover",
        position: "absolute",
        top: isXs ? "-40px" : "-50px",
        left: "50%",
        transform: "translateX(-50%)",
        border: "5px solid #F5EDE3",
      }}
    />
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        marginTop: isXs ? "70px" : "80px",
        marginBottom: "10px",
        fontFamily: "serif",
        fontSize: { xs: "16px", md: "20px" },
      }}
    >
      {service.title}
    </Typography>
    <Typography
      variant="body1"
      textAlign="left"
      sx={{
        color: "#666",
        fontSize: { xs: "14px", md: "16px" },
      }}
    >
      {service.description}
    </Typography>
  </Box>
));

const ServiceHome2 = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  // Retrieve language-specific text from constants
  const { language } = useLanguage();
  const {
    sectionTitle,
    title,
    subtitle,
    services: textServices,
  } = servicesHome2Content[language] || servicesHome2Content["sv"];

  // Combine textServices with local images
  const serviceImages = [nail1, nail2, nail3];
  const services = useMemo(
    () =>
      textServices.map((service, index) => ({
        ...service,
        image: serviceImages[index],
      })),
    [textServices]
  );

  // State to track visibility of each service item (for animations)
  const [isVisibleArray, setIsVisibleArray] = useState(
    new Array(services.length).fill(false)
  );
  const sectionRef = useRef(null);

  // Intersection Observer for scroll-triggered animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          services.forEach((_, index) => {
            setTimeout(() => {
              setIsVisibleArray((prev) => {
                const newArray = [...prev];
                newArray[index] = true;
                return newArray;
              });
            }, index * 300);
          });
          observer.unobserve(entry.target);
        }
      },
      { root: null, rootMargin: "0px", threshold: 0.3 }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, [services]);

  return (
    <Box
      ref={sectionRef}
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
        padding: { xs: "30px 15px", md: "50px 20px" },
        borderRadius: "10px",
        marginBottom: "50px",
      }}
    >
      {/* Section Title */}
      <Typography
        variant="h6"
        sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
      >
        {sectionTitle}
      </Typography>

      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "30px", md: "54px" },
          fontFamily: "'Prata', serif",
          marginBottom: "10px",
          color: "#1C1C2E",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "24px",
          fontFamily: "'Prata', serif",
          marginBottom: "100px",
          color: "#1C1C2E",
        }}
      >
        {subtitle}
      </Typography>

      {/* Services Section */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" },
          gap: { xs: "20px", md: "30px" },
          alignItems: "center",
        }}
      >
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            service={service}
            isXs={isXs}
            isVisible={isVisibleArray[index]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(ServiceHome2);
