import React from "react";
import { Box, Typography } from "@mui/material";
import { useLanguage } from "../../constants/LanguageContext";
import { beautySalonStatsContent } from "../../constants/textConstants";

function BeautySalonStats() {
  const { language } = useLanguage();
  const langKey = language || "sv";
  const { happyClients, skilledSpecialists, monthlyTreatments } =
    beautySalonStatsContent[langKey] || beautySalonStatsContent["sv"];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" },
        gap: { xs: "20px", md: "40px" },
        backgroundColor: "#f8f1e4",
        padding: { xs: "30px 5%", md: "100px 10%" },
        textAlign: "center",
      }}
    >
      {/* Happy Clients */}
      <Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            fontFamily: "'Prata', serif",
            fontSize: { xs: "32px", md: "40px" },
          }}
        >
          500<span style={{ color: "#b9855a" }}>+</span>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            color: "#333",
            fontFamily: "'Prata', serif",
          }}
        >
          {happyClients}
        </Typography>
      </Box>

      {/* Skilled Professionals */}
      <Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            fontFamily: "'Prata', serif",
            fontSize: { xs: "32px", md: "40px" },
          }}
        >
          10<span style={{ color: "#b9855a" }}>+</span>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            color: "#333",
            fontFamily: "'Prata', serif",
          }}
        >
          {skilledSpecialists}
        </Typography>
      </Box>

      {/* Services Done Monthly */}
      <Box>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            fontFamily: "'Prata', serif",
            fontSize: { xs: "32px", md: "40px" },
          }}
        >
          1000<span style={{ color: "#b9855a" }}>+</span>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            color: "#333",
            fontFamily: "'Prata', serif",
          }}
        >
          {monthlyTreatments}
        </Typography>
      </Box>
    </Box>
  );
}

export default BeautySalonStats;
