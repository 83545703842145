// import React, { useState, useEffect } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Box,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// import { Link as ScrollLink, scroller } from "react-scroll";
// import logo from "../assets/1.svg";
// import logo1 from "../assets/logoTransparent.png";
// import LanguageSwitcher from "../constants/LanguageSwitcher";

// // Common link styles for regular (desktop) links
// const linkStyle = {
//   textDecoration: "none",
//   fontSize: "16px",
//   fontWeight: "bold",
//   textTransform: "uppercase",
//   letterSpacing: "1px",
//   transition: "color 0.3s ease-in-out",
//   fontFamily: "'Playfair Display', serif",
//   color: "white",
//   cursor: "pointer",
// };

// // Mobile drawer link styling requested
// const mobileLinkStyle = {
//   fontSize: { xs: "36px", md: "48px" },
//   color: "white",
//   transition: "transform 0.3s ease",
//   ":hover": {
//     textDecoration: "underline",
//     textDecorationColor: "#C19A6B",
//     transform: "scale(1.1)",
//   },
//   // Center text in the button
//   display: "flex",
//   justifyContent: "center",
//   textAlign: "center",
// };

// const scrollToTop = () => {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// };

// function Navbar() {
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 50);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   // Detect and scroll to the section when the page loads
//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const scrollTo = params.get("scrollTo");
//     if (scrollTo) {
//       setTimeout(() => {
//         scroller.scrollTo(scrollTo, {
//           smooth: true,
//           duration: 500,
//           offset: -80,
//         });
//       }, 300);
//     }
//   }, [location]);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const handleNavigation = (e, section) => {
//     e.preventDefault();
//     setMobileOpen(false);
//     if (location.pathname !== "/") {
//       navigate(`/?scrollTo=${section}`);
//       setTimeout(() => {
//         window.location.reload();
//       }, 100);
//     } else {
//       scroller.scrollTo(section, {
//         smooth: true,
//         duration: 500,
//         offset: -80,
//       });
//     }
//   };

//   const handleContactClick = () => {
//     setMobileOpen(false);
//     navigate("/contact");
//   };

//   return (
//     <AppBar
//       position="sticky"
//       sx={{
//         maxWidth: "100%",
//         backgroundColor: isScrolled ? "#1C1C2E" : "transparent",
//         color: "white",
//         boxShadow: "none",
//         transition: "background-color 0.3s, color 0.3s",
//         padding: "10px 0",
//         fontFamily: "'Playfair Display', serif",
//       }}
//     >
//       <Toolbar
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           width: "90%",
//           margin: "auto",
//         }}
//       >
//         {/* Mobile Menu Icon */}
//         <IconButton
//           color="inherit"
//           edge="start"
//           sx={{ display: { md: "none", xs: "block" } }}
//           onClick={handleDrawerToggle}
//         >
//           <MenuIcon />
//         </IconButton>

//         {/* Mobile Drawer with updated styling */}
//         <Drawer
//           anchor="left"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           sx={{
//             "& .MuiDrawer-paper": {
//               width: "250px",
//               backgroundColor: "#1C1C2E",
//               color: "white",
//               fontFamily: "'Playfair Display', serif",
//             },
//           }}
//         >
//           {/* Centered Logo */}
//           <Box
//             component="img"
//             src={logo1}
//             alt="Logo"
//             sx={{
//               width: "50%",
//               height: "auto",
//               objectFit: "contain",
//               marginInline: "auto", // centers horizontally
//               mt: 2, // optional top margin
//               mb: 2, // optional bottom margin
//             }}
//           />

//           {/* The list of links */}
//           <List
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "flex-start",
//               pl: 2, // optional left padding
//             }}
//           >
//             {["Home", "About", "Price", "Services", "Gallery", "Blog"].map(
//               (text, index) => (
//                 <ListItem
//                   key={index}
//                   disablePadding
//                   sx={{ width: "50%", marginInline: "auto" }}
//                 >
//                   <ListItemButton
//                     component={RouterLink}
//                     to={
//                       text.toLowerCase() === "home"
//                         ? "/"
//                         : `/?scrollTo=${text.toLowerCase()}`
//                     }
//                     onClick={(e) => handleNavigation(e, text.toLowerCase())}
//                     sx={{
//                       ...mobileLinkStyle,
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <ListItemText
//                       primary={text}
//                       primaryTypographyProps={{
//                         textAlign: "left",
//                         fontFamily: "'Playfair Display', serif",
//                       }}
//                     />
//                   </ListItemButton>
//                 </ListItem>
//               )
//             )}
//             {/* Language Switcher */}
//             <ListItem
//               disablePadding
//               sx={{
//                 width: "50%",
//                 marginInline: "auto",
//                 mt: 2,
//               }}
//             >
//               <LanguageSwitcher />
//             </ListItem>
//           </List>
//         </Drawer>

//         {/* LEFT LINKS - Hidden on mobile */}
//         <Box>
//           <Box
//             sx={{
//               display: { xs: "none", md: "flex" },
//               alignItems: "center",
//               gap: 4,
//             }}
//           >
//             <RouterLink to="/" style={linkStyle} onClick={scrollToTop}>
//               Home
//             </RouterLink>
//             {["About", "Price", "Services"].map((item, index) =>
//               location.pathname === "/" ? (
//                 <ScrollLink
//                   key={index}
//                   to={item.toLowerCase()}
//                   smooth={true}
//                   duration={500}
//                   offset={-80}
//                   style={linkStyle}
//                 >
//                   {item}
//                 </ScrollLink>
//               ) : (
//                 <RouterLink
//                   key={index}
//                   to={`/?scrollTo=${item.toLowerCase()}`}
//                   style={linkStyle}
//                   onClick={(e) => handleNavigation(e, item.toLowerCase())}
//                 >
//                   {item}
//                 </RouterLink>
//               )
//             )}
//           </Box>
//         </Box>

//         {/* Center Logo: align right on xs, with hover effect */}
//         <Box
//           sx={{
//             // Push the logo to the right on xs
//             marginLeft: { xs: "auto", md: 0 },
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "70px",
//               height: "70px",
//               overflow: "hidden",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               transition: "transform 0.3s ease",
//               "&:hover": {
//                 transform: "scale(1.1) rotate(5deg)",
//               },
//             }}
//           >
//             <Box
//               component="img"
//               src={logo}
//               alt="Logo"
//               sx={{
//                 width: "100%",
//                 height: "auto",
//                 objectFit: "contain",
//               }}
//             />
//           </Box>
//         </Box>

//         {/* RIGHT LINKS - Hidden on mobile */}
//         <Box>
//           <Box sx={{ display: { xs: "none", md: "flex" }, gap: 4 }}>
//             {["Gallery", "Blog"].map((item, index) =>
//               location.pathname === "/" ? (
//                 <ScrollLink
//                   key={index}
//                   to={item.toLowerCase()}
//                   smooth={true}
//                   duration={500}
//                   offset={-80}
//                   style={linkStyle}
//                 >
//                   {item}
//                 </ScrollLink>
//               ) : (
//                 <RouterLink
//                   key={index}
//                   to={`/?scrollTo=${item.toLowerCase()}`}
//                   style={linkStyle}
//                   onClick={(e) => handleNavigation(e, item.toLowerCase())}
//                 >
//                   {item}
//                 </RouterLink>
//               )
//             )}

//             {/* Contact Page Link */}
//             <RouterLink
//               to="/contact"
//               style={linkStyle}
//               onClick={handleContactClick}
//             >
//               Contact
//             </RouterLink>

//             <Box
//               sx={{
//                 border: "0.5px solid #c29d6a",
//                 marginTop: "-12px",
//                 boxShadow: "2px 3px 6px rgba(194, 157, 106, 0.5)",
//                 borderRadius: "4px",
//               }}
//             >
//               <LanguageSwitcher />
//             </Box>
//           </Box>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// }

// export default Navbar;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import logo from "../assets/1.svg";
import logo1 from "../assets/logoTransparent.png";
import LanguageSwitcher from "../constants/LanguageSwitcher";

// Common link styles for regular (desktop) links
const linkStyle = {
  textDecoration: "none",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "1px",
  transition: "color 0.3s ease-in-out",
  fontFamily: "'Playfair Display', serif",
  color: "white",
  cursor: "pointer",
};

// Mobile drawer link styling
const mobileLinkStyle = {
  fontSize: { xs: "36px", md: "48px" },
  color: "white",
  transition: "transform 0.3s ease",
  textDecoration: "none",
  ":hover": {
    textDecoration: "underline",
    textDecorationColor: "#C19A6B",
    transform: "scale(1.3)",
  },
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");
    if (scrollTo) {
      setTimeout(() => {
        scroller.scrollTo(scrollTo, {
          smooth: true,
          duration: 500,
          offset: -80,
        });
      }, 300);
    }
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (e, section) => {
    e.preventDefault();
    setMobileOpen(false);
    if (location.pathname !== "/") {
      navigate(`/?scrollTo=${section}`);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      scroller.scrollTo(section, {
        smooth: true,
        duration: 500,
        offset: -80,
      });
    }
  };

  const handleContactClick = () => {
    setMobileOpen(false);
    navigate("/contact");
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        maxWidth: "100%",
        backgroundColor: isScrolled ? "#1C1C2E" : "transparent",
        color: "white",
        boxShadow: "none",
        transition: "background-color 0.3s, color 0.3s",
        padding: "10px 0",
        fontFamily: "'Playfair Display', serif",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "90%",
          margin: "auto",
        }}
      >
        <IconButton
          color="inherit"
          edge="start"
          sx={{ display: { md: "none", xs: "block" } }}
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              width: "250px",
              backgroundColor: "#1C1C2E",
              color: "white",
              fontFamily: "'Playfair Display', serif",
            },
          }}
        >
          <Box
            component="img"
            src={logo1}
            alt="Logo"
            sx={{ width: "50%", marginInline: "auto", mt: 2, mb: 2 }}
          />

          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              pl: 2,
            }}
          >
            {["Home", "About", "Price", "Services", "Gallery", "Blog"].map(
              (text, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ width: "50%", marginInline: "auto" }}
                >
                  <a
                    href={
                      text.toLowerCase() === "home"
                        ? "/"
                        : `/?scrollTo=${text.toLowerCase()}`
                    }
                    style={{ width: "100%", textDecoration: "none" }}
                  >
                    <ListItemButton
                      sx={{ ...mobileLinkStyle, justifyContent: "flex-start" }}
                      onClick={(e) => handleNavigation(e, text.toLowerCase())}
                    >
                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                          textAlign: "left",
                          fontFamily: "'Playfair Display', serif",
                          sx: { textDecoration: "none" },
                        }}
                      />
                    </ListItemButton>
                  </a>
                </ListItem>
              )
            )}

            {/* 🔹 Add "Contact" link in the mobile drawer */}
            <ListItem
              disablePadding
              sx={{ width: "50%", marginInline: "auto" }}
            >
              <a
                href="/contact"
                style={{ width: "100%", textDecoration: "none" }}
              >
                <ListItemButton
                  sx={{ ...mobileLinkStyle, justifyContent: "flex-start" }}
                  onClick={handleContactClick}
                >
                  <ListItemText
                    primary="Contact"
                    primaryTypographyProps={{
                      textAlign: "left",
                      fontFamily: "'Playfair Display', serif",
                    }}
                  />
                </ListItemButton>
              </a>
            </ListItem>

            {/* Language Switcher */}
            <ListItem
              disablePadding
              sx={{ width: "50%", marginInline: "auto", mt: 2 }}
            >
              <LanguageSwitcher />
            </ListItem>
          </List>
        </Drawer>

        <Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 4,
            }}
          >
            <a href="/" style={linkStyle} onClick={scrollToTop}>
              Home
            </a>
            {["About", "Price", "Services"].map((item, index) => (
              <a
                key={index}
                href={`/?scrollTo=${item.toLowerCase()}`}
                style={linkStyle}
                onClick={(e) => handleNavigation(e, item.toLowerCase())}
              >
                {item}
              </a>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            marginLeft: { xs: "auto", md: 0 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "70px",
              height: "70px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "transform 0.3s ease",
              "&:hover": { transform: "scale(1.1) rotate(5deg)" },
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 4,
              alignItems: "center",
            }}
          >
            {["Gallery", "Blog"].map((item, index) => (
              <a
                key={index}
                href={`/?scrollTo=${item.toLowerCase()}`}
                style={linkStyle}
                onClick={(e) => handleNavigation(e, item.toLowerCase())}
              >
                {item}
              </a>
            ))}
            <a href="/contact" style={linkStyle} onClick={handleContactClick}>
              Contact
            </a>
            <Box
              sx={{
                border: "0.5px solid #c29d6a",
                marginTop: "-12px",
                boxShadow: "2px 3px 6px rgba(194, 157, 106, 0.5)",
                borderRadius: "4px",
              }}
            >
              <LanguageSwitcher />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
