// import React from "react";
// import { Box, Typography, Button } from "@mui/material";
// import priceBackground from "../../assets/salon.jpg";
// import { useLanguage } from "../../constants/LanguageContext";
// import { pricingContent } from "../../constants/textConstants";

// const Price = React.memo(() => {
//   const { language } = useLanguage();
//   const langKey = language || "sv"; // Fallback to Swedish
//   const { sectionTitle, title, buttonText, pricingData } =
//     pricingContent[langKey] || pricingContent["sv"];

//   return (
//     <section
//       id="price"
//       aria-labelledby="pricing-heading"
//       style={{
//         position: "relative",
//         textAlign: "center",
//         padding: "60px 10%",
//         color: "white",
//         backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${priceBackground})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//         backgroundAttachment: "fixed",
//         fontFamily: "'Prata', serif",
//       }}
//     >
//       {/* Pricing Header */}
//       <Typography
//         variant="h6"
//         sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
//       >
//         {sectionTitle}
//       </Typography>
//       <Typography
//         id="pricing-heading"
//         component="h2"
//         sx={{
//           fontWeight: "bold",
//           fontSize: { xs: "30px", md: "54px" },
//           fontFamily: "'Prata', serif",
//           marginBottom: "50px",
//         }}
//       >
//         {title}
//       </Typography>

//       {/* Pricing Grid */}
//       <Box
//         component="div"
//         sx={{
//           display: "grid",
//           gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
//           gap: { xs: "40px", md: "20px" },
//         }}
//       >
//         {pricingData.map((section, index) => (
//           <Box
//             key={index}
//             component="article"
//             aria-labelledby={`category-${index}`}
//           >
//             {/* Section Header */}
//             <Typography
//               component="h3"
//               id={`category-${index}`}
//               sx={{
//                 backgroundColor: "#db9d47",
//                 padding: "12px",
//                 fontWeight: "bold",
//                 color: "#1C1C2E",
//                 textAlign: "center",
//                 fontFamily: "'Prata', serif",
//               }}
//             >
//               {section.category}
//             </Typography>

//             {/* Pricing Items */}
//             {section.services.map((item, i) => (
//               <React.Fragment key={i}>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     padding: "10px 0",
//                     borderBottom: "1px dotted rgba(255, 255, 255, 0.5)",
//                     fontFamily: "'Prata', serif",
//                   }}
//                 >
//                   <Typography
//                     variant="body1"
//                     sx={{ flexGrow: 1, textAlign: "left" }}
//                   >
//                     {item.name}
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     sx={{ fontWeight: "bold", textAlign: "right" }}
//                   >
//                     {item.price}
//                   </Typography>
//                 </Box>
//               </React.Fragment>
//             ))}
//           </Box>
//         ))}
//       </Box>

//       {/* "See Pricing" Button */}
//       <a
//         href="https://www.bokadirekt.se/places/salong-team-ett-sodermalm-60828"
//         target="_blank"
//         rel="noopener noreferrer"
//         style={{ textDecoration: "none" }}
//       >
//         <Button
//           variant="contained"
//           sx={{
//             mt: 4,
//             backgroundColor: "white",
//             color: "black",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             fontFamily: "'Prata', serif",
//             "&:hover": { backgroundColor: "#E3C187", color: "white" },
//           }}
//         >
//           {buttonText}
//         </Button>
//       </a>
//     </section>
//   );
// });

// export default Price;

import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import priceBackground from "../../assets/salon.jpg";
import { useLanguage } from "../../constants/LanguageContext";

const Price = React.memo(() => {
  const { language } = useLanguage();
  const langKey = language || "sv"; // Default to Swedish
  const [pricing, setPricing] = useState(null);

  useEffect(() => {
    fetch("/pricing.json")
      .then((response) => response.json())
      .then((data) => setPricing(data[langKey] || data["sv"]))
      .catch((error) => console.error("Failed to load pricing data", error));
  }, [langKey]);

  if (!pricing) {
    return <Typography>Loading prices...</Typography>;
  }

  return (
    <section
      id="price"
      aria-labelledby="pricing-heading"
      style={{
        position: "relative",
        textAlign: "center",
        padding: "60px 10%",
        color: "white",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${priceBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        fontFamily: "'Prata', serif",
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
      >
        {pricing.sectionTitle}
      </Typography>
      <Typography
        id="pricing-heading"
        component="h2"
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "30px", md: "54px" },
          fontFamily: "'Prata', serif",
          marginBottom: "50px",
        }}
      >
        {pricing.title}
      </Typography>

      <Box
        component="div"
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
          gap: { xs: "40px", md: "20px" },
        }}
      >
        {pricing.pricingData.map((section, index) => (
          <Box
            key={index}
            component="article"
            aria-labelledby={`category-${index}`}
          >
            <Typography
              component="h3"
              id={`category-${index}`}
              sx={{
                backgroundColor: "#db9d47",
                padding: "12px",
                fontWeight: "bold",
                color: "#1C1C2E",
                textAlign: "center",
                fontFamily: "'Prata', serif",
              }}
            >
              {section.category}
            </Typography>

            {section.services.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0",
                  borderBottom: "1px dotted rgba(255, 255, 255, 0.5)",
                  fontFamily: "'Prata', serif",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ flexGrow: 1, textAlign: "left" }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  {item.price}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <a
        href="https://www.bokadirekt.se/places/salong-team-ett-sodermalm-60828"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          sx={{
            mt: 4,
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            padding: "10px 20px",
            fontFamily: "'Prata', serif",
            "&:hover": { backgroundColor: "#E3C187", color: "white" },
          }}
        >
          {pricing.buttonText}
        </Button>
      </a>
    </section>
  );
});

export default Price;
