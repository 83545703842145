import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";
import { useLanguage } from "../../constants/LanguageContext";
import { blogPostsContent } from "../../constants/textConstants";
import s1 from "../../assets/men/4.avif";
import s2 from "../../assets/s1.jpg";
import s3 from "../../assets/hairImages/11.avif";
import s4 from "../../assets/nails/5.avif";
import s5 from "../../assets/s5.jpg";
import s6 from "../../assets/s6.jpg";

const imageMap = {
  s1,
  s2,
  s3,
  s4,
  s5,
  s6,
};

// Reusable styles
const cardStyles = {
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  },
};

const BlogPostCard = React.memo(
  ({ post, index, handlePostClick, readMore }) => {
    return (
      <Card sx={cardStyles} onClick={() => handlePostClick(index)}>
        {/* Blog Image */}
        <CardMedia
          component="img"
          height="260"
          image={imageMap[post.image] || post.image}
          alt={post.title}
          loading="lazy"
          srcSet={`${imageMap[post.image] || post.image}?w=400&format=avif 400w,
                 ${imageMap[post.image] || post.image}?w=800&format=avif 800w`}
          sizes="(max-width: 600px) 400px, 800px"
          sx={{ width: "100%" }}
        />

        {/* Blog Content */}
        <CardContent sx={{ textAlign: "left", padding: "20px 24px" }}>
          <Typography
            variant="body2"
            sx={{
              color: "#C19A6B",
              fontWeight: "bold",
              fontSize: "14px",
              fontFamily: `"Playfair Display", serif`,
            }}
          >
            {post.category} /{" "}
            <span style={{ color: "#777" }}>By {post.author}</span>
          </Typography>

          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              margin: "10px 0",
              fontFamily: `"Playfair Display", serif`,
            }}
          >
            {post.title}
          </Typography>

          <Typography variant="body2" sx={{ color: "#666", fontSize: "16px" }}>
            {post.description}
          </Typography>

          {/* Read More */}
          <Typography
            variant="body2"
            sx={{
              marginTop: "20px",
              fontWeight: "bold",
              fontSize: "14px",
              cursor: "pointer",
              textDecoration: "underline",
              display: "inline-block",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click from triggering
              handlePostClick(index);
            }}
          >
            {readMore}
          </Typography>
        </CardContent>
      </Card>
    );
  }
);

const BlogHome = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const langKey = language || "sv";
  const blogPosts = useMemo(
    () => blogPostsContent[langKey] || blogPostsContent["sv"],
    [langKey]
  );

  const handlePostClick = (index) => {
    navigate(`/blog?postIndex=${index}`);
  };

  return (
    <Box
      id="blog"
      sx={{
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
        padding: "60px 20px",
      }}
    >
      {/* Title */}
      <Typography
        variant="h6"
        sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
      >
        {blogPosts.sectionTitle}
      </Typography>

      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "30px", md: "54px" },
          fontFamily: "'Prata', serif",
          marginBottom: "10px",
          color: "#1C1C2E",
        }}
      >
        {blogPosts.title}
      </Typography>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "24px",
          fontFamily: "'Prata', serif",
          marginBottom: "50px",
          color: "#1C1C2E",
        }}
      >
        {blogPosts.subtitle}
      </Typography>

      {/* Blog Cards Container */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr 1fr" },
          gap: "30px",
        }}
      >
        {blogPosts.posts.map((post, index) => (
          <BlogPostCard
            key={index}
            post={post}
            index={index}
            handlePostClick={handlePostClick}
            readMore={blogPosts.readMore}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(BlogHome);
