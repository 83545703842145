import React, { useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { useLanguage } from "../../constants/LanguageContext";
import { videoContent } from "../../constants/textConstants";

const video1 = "../../assets/video/11.mp4";

// Styled video element (no controls)
const Video = styled("video")({
  width: "100%",
  height: "100%",
  border: "1px solid #ccc",
  borderRadius: "4px",
  backgroundColor: "transparent",
});

// Left column for the video
const LeftColumn = styled(Box)({
  maxWidth: "100%",
  margin: "0 auto",
});

const VideoComponent = () => {
  const { language } = useLanguage();
  const { sectionTitle, title, subtitle, description } =
    videoContent[language] || videoContent["sv"];

  const [loading, setLoading] = useState(true);

  const handleCanPlay = () => setLoading(false);
  const handleVideoError = () => console.error("Error loading video.");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        maxWidth: "1200px",
        margin: "auto",
        gap: 4,
        marginBlock: { xs: "50px", md: "100px" },
        padding: { xs: "10px 20px", md: "50px 10%" },
      }}
    >
      {/* LEFT COLUMN: Video */}
      <LeftColumn
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {loading && (
          <Box
            sx={{
              width: "100%",
              height: "280px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Video
          autoPlay
          loop
          muted
          onCanPlayThrough={handleCanPlay}
          onError={handleVideoError}
          poster="https://placehold.co/300x280?text=Loading+Video..."
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      </LeftColumn>

      {/* RIGHT COLUMN: Text Content */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "right",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#db9d47", fontWeight: "bold", mb: 1 }}
        >
          {sectionTitle}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "30px", md: "54px" },
            fontFamily: "'Prata', serif",
            marginBottom: "10px",
            color: "#1C1C2E",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: "20px",
            fontFamily: "'Prata', serif",
            marginBottom: "20px",
            color: "#1C1C2E",
          }}
        >
          {subtitle}
        </Typography>
        <Typography
          variant="body1"
          textAlign="left"
          sx={{ color: "#555", lineHeight: 1.7, mb: 3 }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default VideoComponent;
